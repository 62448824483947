import React, { useContext, useEffect } from 'react';
import { Routes, Route, Outlet } from 'react-router';
import { observer } from 'mobx-react-lite';
import { ThemeProvider } from 'styled-components';
import { useLocation, Navigate } from 'react-router-dom';
import ReactTooltip from 'react-tooltip';
import { AppContext } from 'app/store';
import { Theme, Pages } from 'app/constants';
import { GlobalStyle, LightTheme, DarkTheme } from 'app/theme';
import { Header, Footer, PageScrollTracker, Loader, ErrorMessage } from 'app/components';
import { OfflineIndicator } from 'app/components/offline-indicator';
import { network } from 'app/api';
import { useOnlineStatus } from 'hooks/use-online-status';
import { MyProfileView } from '../my-profile';
import { LoginView } from '../login';
import { SearchResultView } from '../search-result';
import { PersonProfileView } from '../person-profile';
import { version } from '../../../package.json';
import { useAuth } from '../../hooks';
export var RootView = observer(function () {
    var _a = useContext(AppContext), dataStore = _a.dataStore, portalStore = _a.portalStore, userStore = _a.userStore;
    var _b = useAuth(), isLoggedIn = _b.isLoggedIn, isAuthPending = _b.isLoading;
    var routerLocation = useLocation();
    var isOnline = useOnlineStatus().isOnline;
    network.onErrorFn = function (error) {
        portalStore.setError(error);
    };
    useEffect(function () {
        if (!isAuthPending && isLoggedIn) {
            userStore.fetchUser();
            dataStore.fetchData();
        }
    }, [isAuthPending, isLoggedIn]);
    var HeaderOutlet = function () { return (React.createElement(React.Fragment, null,
        React.createElement(Header, { showProfileIcon: routerLocation.pathname !== Pages.Profile }),
        React.createElement(Outlet, null))); };
    var LoggedInRoutes = function () { return (React.createElement(Routes, null,
        React.createElement(Route, { element: React.createElement(HeaderOutlet, null) },
            React.createElement(Route, { path: "/", element: React.createElement(SearchResultView, null) }),
            React.createElement(Route, { path: "/person/*", element: React.createElement(PersonProfileView, null) }),
            React.createElement(Route, { path: "/profile/*", element: React.createElement(MyProfileView, null) })))); };
    var getSelectedTheme = function () {
        switch (portalStore.theme) {
            case Theme.Light:
                return LightTheme;
            case Theme.Dark:
                return DarkTheme;
            default:
                return LightTheme;
        }
    };
    return (React.createElement(ThemeProvider, { theme: getSelectedTheme() },
        React.createElement(React.Fragment, null,
            React.createElement(OfflineIndicator, null),
            React.createElement(PageScrollTracker, null),
            React.createElement(GlobalStyle, null),
            isOnline && (React.createElement(ErrorMessage, null)) /* TODO: Disables errors if offline. Enable if there are other errors than API related. */,
            isAuthPending ? (React.createElement(Loader, { title: "Authenticating.." })) : (React.createElement(Routes, null,
                React.createElement(Route, { path: "/*", element: isLoggedIn ? React.createElement(LoggedInRoutes, null) : React.createElement(Navigate, { replace: true, to: "/login" }) }),
                React.createElement(Route, { path: "/login", element: isLoggedIn ? React.createElement(Navigate, { replace: true, to: "/" }) : React.createElement(LoginView, null) }))),
            React.createElement(Footer, { versionNumber: version }),
            React.createElement(ReactTooltip, { delayShow: 200, effect: "solid", id: "tooltip" }))));
});
