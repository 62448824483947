var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
import React from 'react';
import styled from 'styled-components';
import Highlighter from 'react-highlight-words';
import { SkillLevel } from 'components/skill-level';
import { FaTimes } from 'react-icons/fa';
var SkillComponent = function (_a) {
    var skill = _a.skill, color = _a.color, onChange = _a.onChange, _b = _a.editable, editable = _b === void 0 ? false : _b, highlight = _a.highlight, onClick = _a.onClick, className = _a.className;
    var changeScore = function (score) {
        if (onChange) {
            onChange(skill.id, score);
        }
    };
    var getSkillTitle = function (score) {
        var titles = [
            'Clear',
            'Basic knowledge, needs frequent assistance to work in a project',
            'Knowledgeable, requires only little assistance',
            'Expert, works independently and can help others',
        ];
        return titles[score];
    };
    var onSkillClick = function (event) {
        event.preventDefault();
        event.stopPropagation();
        if (typeof onClick === 'function' && !editable) {
            onClick(skill);
        }
    };
    var renderEditButtons = function () {
        var selectableValues = [1, 2, 3];
        var editButtons = selectableValues.map(function (value) { return (React.createElement(SkillLevelButton, { key: "".concat(skill.id).concat(value), onClick: function () { return changeScore(value); }, color: color, level: skill.score, "data-tip": getSkillTitle(value), "data-for": "tooltip" },
            React.createElement(SkillLevel, { fillColor: color, size: "1em", skillLevel: skill.score >= value ? 3 : 1 }))); });
        if (skill.score > 0) {
            editButtons.push(React.createElement(SkillLevelButton, { key: "".concat(skill.id, "0"), onClick: function () { return changeScore(0); }, color: color, level: skill.score, "data-tip": getSkillTitle(0), "data-for": "tooltip" },
                React.createElement(RemoveIcon, null)));
        }
        return React.createElement(EditButtonsWrapper, null, editButtons);
    };
    var renderSkillLevel = function (score) {
        var levels = [1, 2, 3].map(function (level) { return (React.createElement(SkillLevel, { key: level, skillLevel: score >= level ? 3 : 1, size: "1em" })); });
        return React.createElement(SkillLevelWrapper, null, levels);
    };
    return (React.createElement(Wrapper, { editable: editable, className: className },
        React.createElement(SkillButton, { color: color, level: skill.score, onClick: onSkillClick },
            React.createElement(Highlighter, { highlightClassName: "highlighted-text", searchWords: highlight ? [highlight] : [], autoEscape: true, textToHighlight: skill.name }),
            skill.score > 0 && !editable && renderSkillLevel(skill.score)),
        editable && renderEditButtons()));
};
var levelIsAboveZeroOrUndefined = function (level) {
    if (level === undefined || level > 0) {
        return true;
    }
    return false;
};
var Wrapper = styled.div(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  display: flex;\n  margin: ", ";\n"], ["\n  display: flex;\n  margin: ", ";\n"])), function (_a) {
    var editable = _a.editable;
    return (editable ? '0 0 1rem 0' : '0.85rem 1rem 0.85rem 0');
});
var SkillButton = styled.button(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n  display: inline-flex;\n  align-items: center;\n  color: ", ";\n  background-color: ", ";\n  border-radius: ", ";\n  border: 0.2rem solid ", ";\n  padding: 0 1rem;\n  margin: 0;\n  font-size: 1.6rem;\n  font-family: ", ";\n  font-weight: 600;\n  height: 3rem;\n  overflow: hidden;\n  transition: all 0.3s ease-out;\n  cursor: pointer;\n\n  &:hover {\n    border: 0.2rem solid #fff;\n  }\n"], ["\n  display: inline-flex;\n  align-items: center;\n  color: ", ";\n  background-color: ", ";\n  border-radius: ", ";\n  border: 0.2rem solid ", ";\n  padding: 0 1rem;\n  margin: 0;\n  font-size: 1.6rem;\n  font-family: ", ";\n  font-weight: 600;\n  height: 3rem;\n  overflow: hidden;\n  transition: all 0.3s ease-out;\n  cursor: pointer;\n\n  &:hover {\n    border: 0.2rem solid #fff;\n  }\n"])), function (_a) {
    var level = _a.level, color = _a.color;
    return (levelIsAboveZeroOrUndefined(level) ? '#fff' : color);
}, function (_a) {
    var level = _a.level, color = _a.color;
    return levelIsAboveZeroOrUndefined(level) ? color : 'transparent';
}, function (_a) {
    var theme = _a.theme;
    return theme.borderRadius.small;
}, function (_a) {
    var color = _a.color;
    return color;
}, function (_a) {
    var theme = _a.theme;
    return theme.font.secondary;
});
var SkillLevelWrapper = styled.div(templateObject_3 || (templateObject_3 = __makeTemplateObject(["\n  display: inherit;\n  margin-left: 0.8rem;\n\n  & > span {\n    margin-left: 0.4rem;\n  }\n"], ["\n  display: inherit;\n  margin-left: 0.8rem;\n\n  & > span {\n    margin-left: 0.4rem;\n  }\n"])));
var SkillLevelButton = styled.button(templateObject_4 || (templateObject_4 = __makeTemplateObject(["\n  display: inline-flex;\n  align-items: center;\n  border: 0;\n  overflow: hidden;\n  background: transparent;\n  cursor: pointer;\n  justify-content: center;\n  color: ", ";\n  height: 3rem;\n  width: 2.8rem;\n  padding: 0;\n  font-size: 2rem;\n  font-weight: bold;\n  transition: all 0.3s ease-out;\n  outline: none;\n\n  &:hover {\n    transform: scale3d(1.1, 1.1, 1);\n  }\n"], ["\n  display: inline-flex;\n  align-items: center;\n  border: 0;\n  overflow: hidden;\n  background: transparent;\n  cursor: pointer;\n  justify-content: center;\n  color: ", ";\n  height: 3rem;\n  width: 2.8rem;\n  padding: 0;\n  font-size: 2rem;\n  font-weight: bold;\n  transition: all 0.3s ease-out;\n  outline: none;\n\n  &:hover {\n    transform: scale3d(1.1, 1.1, 1);\n  }\n"])), function (_a) {
    var color = _a.color;
    return color;
});
var RemoveIcon = styled(FaTimes)(templateObject_5 || (templateObject_5 = __makeTemplateObject(["\n  width: 1em;\n  height: 1em;\n  color: ", ";\n"], ["\n  width: 1em;\n  height: 1em;\n  color: ", ";\n"])), function (_a) {
    var theme = _a.theme;
    return theme.color.txt1;
});
var EditButtonsWrapper = styled.div(templateObject_6 || (templateObject_6 = __makeTemplateObject(["\n  display: inherit;\n  margin-left: 1rem;\n"], ["\n  display: inherit;\n  margin-left: 1rem;\n"
    // Name for test
])));
// Name for test
SkillLevelButton.displayName = 'SkillLevelButton';
export var Skill = SkillComponent;
var templateObject_1, templateObject_2, templateObject_3, templateObject_4, templateObject_5, templateObject_6;
