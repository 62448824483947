// TODO: move this file to utils
var DataStorage = /** @class */ (function () {
    function DataStorage() {
    }
    /**
     * Add item to localstorage
     *
     * @param key Data key
     * @param value Data Value
     * @param maxAge MaxAge in milliseconds
     *
     * @returns true if success
     */
    Object.defineProperty(DataStorage, "setPersistItem", {
        enumerable: false,
        configurable: true,
        writable: true,
        value: function (key, value) {
            var item = {
                value: value,
                modified: Date.now(),
            };
            try {
                localStorage.setItem(key, JSON.stringify(item));
                return true;
            }
            catch (e) {
                logError('Error while saving data to local storage: ', e);
                return false;
            }
        }
    });
    /**
     * Load item from local storage
     *
     * @param key Item key
     */
    Object.defineProperty(DataStorage, "getPersistItem", {
        enumerable: false,
        configurable: true,
        writable: true,
        value: function (key, maxAge) {
            if (maxAge === void 0) { maxAge = 0; }
            try {
                var item = localStorage.getItem(key);
                if (item) {
                    var parsed = JSON.parse(item);
                    if (maxAge === 0 || parsed.modified + maxAge > Date.now()) {
                        return parsed.value;
                    }
                }
                return null;
            }
            catch (e) {
                logError('Error while loading item from local storage: ', e);
                return null;
            }
        }
    });
    /**
     * Remove item from localstorage
     *
     * @param key
     */
    Object.defineProperty(DataStorage, "removePersistItem", {
        enumerable: false,
        configurable: true,
        writable: true,
        value: function (key) {
            try {
                localStorage.removeItem(key);
            }
            catch (e) {
                logError('Error while removing item from local storage: ', e);
            }
        }
    });
    /**
     * Add item to session storage
     *
     * @param key Data key
     * @param value Data Value
     * @param maxAge MaxAge in milliseconds
     *
     * @returns true if success
     */
    Object.defineProperty(DataStorage, "setSessionItem", {
        enumerable: false,
        configurable: true,
        writable: true,
        value: function (key, value) {
            var item = {
                value: value,
                modified: Date.now(),
            };
            try {
                sessionStorage.setItem(key, JSON.stringify(item));
                return true;
            }
            catch (e) {
                logError('Error while saving data to session storage: ', e);
                return false;
            }
        }
    });
    /**
     * Load item from session storage
     *
     * @param key Item key
     */
    Object.defineProperty(DataStorage, "getSessionItem", {
        enumerable: false,
        configurable: true,
        writable: true,
        value: function (key, maxAge) {
            if (maxAge === void 0) { maxAge = 0; }
            try {
                var item = sessionStorage.getItem(key);
                if (item) {
                    var parsed = JSON.parse(item);
                    if (maxAge === 0 || parsed.modified + maxAge > Date.now()) {
                        return parsed.value;
                    }
                }
                return null;
            }
            catch (e) {
                logError('Error while loading item from session storage: ', e);
                return null;
            }
        }
    });
    /**
     * Remove item from session storage
     *
     * @param key
     */
    Object.defineProperty(DataStorage, "removeSessionItem", {
        enumerable: false,
        configurable: true,
        writable: true,
        value: function (key) {
            try {
                sessionStorage.removeItem(key);
            }
            catch (e) {
                logError('Error while removing item from session storage: ', e);
            }
        }
    });
    return DataStorage;
}());
export default DataStorage;
// Fix for error type error
function logError(message, e) {
    if (e instanceof Error) {
        console.error(message, e.message); // eslint-disable-line no-console
    }
    else {
        console.log('Unexpected error', e); // eslint-disable-line no-console
    }
}
