import { useState, useEffect, useCallback } from 'react';
import { useLocation } from 'react-router-dom';
import { debounce } from 'lodash';
var PageScrollTrackerComponent = function () {
    var location = useLocation();
    var _a = useState(0), bodyHeight = _a[0], setBodyHeight = _a[1];
    var _b = useState({ boolean: false, position: 0 }), shouldScroll = _b[0], setShouldScroll = _b[1];
    var scrollListener = useCallback(debounce(function () {
        window.sessionStorage.setItem("scrollPosition-".concat(location.key), "".concat(window.scrollY));
    }, 100), []);
    useEffect(function () {
        window.addEventListener('scroll', scrollListener);
        return function () { return window.removeEventListener('scroll', scrollListener); };
    }, []);
    useEffect(function () {
        var bodyHeightWatcher = window.setInterval(function () {
            setBodyHeight(document.body.clientHeight);
        }, 50);
        return function () { return window.clearInterval(bodyHeightWatcher); };
    }, []);
    useEffect(function () {
        var target = Number(window.sessionStorage.getItem("scrollPosition-".concat(location.key)));
        var newScroll = { boolean: true, position: target || 0 };
        setShouldScroll(newScroll);
    }, [location.key]);
    useEffect(function () {
        if (bodyHeight > shouldScroll.position && shouldScroll.boolean) {
            window.scrollTo(0, shouldScroll.position);
            setShouldScroll({ boolean: false, position: 0 });
        }
    }, [bodyHeight]);
    return null;
};
export var PageScrollTracker = PageScrollTrackerComponent;
