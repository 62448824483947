export var registerServiceWorker = function () {
    if ('serviceWorker' in navigator) {
        navigator.serviceWorker.getRegistrations().then(function (registrations) {
            registrations.forEach(function (registration) { return registration.unregister(); });
        });
        // SW disabled
        /* window.addEventListener('load', () => {
          navigator.serviceWorker.register('/service-worker.js', { scope: '/' })
        }) */
    }
};
