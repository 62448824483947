var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import axios from 'axios';
import { APP_URL } from 'app/constants';
import { AppError } from 'app/types';
import { firebaseAuth } from '../utils/firebase';
var DEFAULT_TIMEOUT = 10000; // 10s
var DEFAULT_HEADERS = {
    Accept: 'application/json',
    'Content-Type': 'application/json',
};
var DEFAULT_HTTP_CLIENT_CONFIG = {
    baseURL: APP_URL,
    headers: DEFAULT_HEADERS,
    timeout: DEFAULT_TIMEOUT,
    withCredentials: false,
};
var REFRESH_TOKEN_INTERVAL = 50 * 60 * 1000; // 50min
var Networking = /** @class */ (function () {
    function Networking() {
        var _this = this;
        Object.defineProperty(this, "httpClient", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
        Object.defineProperty(this, "refreshTokenTimer", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
        Object.defineProperty(this, "onErrorFn", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
        Object.defineProperty(this, "errorHandler", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: function (error) {
                if (_this.onErrorFn) {
                    _this.onErrorFn(_this.convertToAppError(error));
                }
                return Promise.reject(error);
            }
        });
        Object.defineProperty(this, "convertToAppError", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: function (error) {
                var errorData;
                var response = error.response;
                var data = response && response.data;
                var responseStatus = response && response.status;
                if (
                /**
                 * When user has internet connection and
                 * connection times out, we get ECONNABORTED.
                 *
                 * If wifi is disconnected etc
                 * response.status will be 504
                 */
                (error.isAxiosError && error.code === 'ECONNABORTED') ||
                    responseStatus === 504) {
                    errorData = {
                        code: 504,
                        title: 'Request timeout',
                        reason: "Request timeout ".concat(DEFAULT_TIMEOUT / 1000, "s exceeded."),
                    };
                }
                else {
                    // Default error
                    errorData = __assign(__assign({}, AppError.apiError), { code: responseStatus || AppError.apiError.code, reason: (data && data.message) || error.message });
                }
                return errorData;
            }
        });
        Object.defineProperty(this, "refreshAccessToken", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: function () {
                var user = firebaseAuth.currentUser;
                if (user) {
                    /**
                     * Force token refresh, this will trigger onIdTokenChanged
                     * listener which updates the token to network also
                     */
                    return user.getIdToken(true);
                }
                return Promise.resolve(null);
            }
        });
        Object.defineProperty(this, "setAccessToken", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: function (token) {
                if (_this.refreshTokenTimer) {
                    clearTimeout(_this.refreshTokenTimer);
                }
                /**
                 * We use timer to refresh token when user is iddle
                 */
                _this.refreshTokenTimer = setTimeout(function () { return _this.refreshAccessToken(); }, REFRESH_TOKEN_INTERVAL);
                _this.httpClient.defaults.headers.Authorization = "Bearer ".concat(token);
            }
        });
        Object.defineProperty(this, "httpGet", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: function (url, options) {
                if (options === void 0) { options = {}; }
                return _this.httpClient.get(url, options);
            }
        });
        Object.defineProperty(this, "httpPost", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: function (url, data, options) {
                if (data === void 0) { data = {}; }
                if (options === void 0) { options = {}; }
                return _this.httpClient.post(url, data, options);
            }
        });
        Object.defineProperty(this, "httpPatch", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: function (url, data, options) {
                if (data === void 0) { data = {}; }
                if (options === void 0) { options = {}; }
                return _this.httpClient.patch(url, data, options);
            }
        });
        Object.defineProperty(this, "httpPut", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: function (url, data, options) {
                if (data === void 0) { data = {}; }
                if (options === void 0) { options = {}; }
                return _this.httpClient.put(url, data, options);
            }
        });
        Object.defineProperty(this, "httpDelete", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: function (url, options) {
                if (options === void 0) { options = {}; }
                return _this.httpClient.delete(url, options);
            }
        });
        this.httpClient = axios.create(DEFAULT_HTTP_CLIENT_CONFIG);
        this.httpClient.interceptors.response.use(undefined, this.errorHandler);
    }
    return Networking;
}());
export var network = new Networking();
