var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import React from 'react';
import styled, { css } from 'styled-components';
import { breakpoints } from './grid-settings';
export var FlexContainer = function (_a) {
    var children = _a.children, _b = _a.wrap, wrap = _b === void 0 ? true : _b, _c = _a.alignRight, alignRight = _c === void 0 ? false : _c, _d = _a.fluid, fluid = _d === void 0 ? false : _d, rest = __rest(_a, ["children", "wrap", "alignRight", "fluid"]);
    return (React.createElement(Container, __assign({}, rest, { doWrap: wrap, alignRight: alignRight, fluid: fluid }), children));
};
var Container = styled.div(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n  display: flex;\n  flex-wrap: ", ";\n  justify-content: ", ";\n  margin: 0 auto;\n  width: 100%;\n\n  ", "\n"], ["\n  display: flex;\n  flex-wrap: ", ";\n  justify-content: ", ";\n  margin: 0 auto;\n  width: 100%;\n\n  ", "\n"])), function (props) { return (props.doWrap ? 'wrap' : 'nowrap'); }, function (props) { return (props.alignRight ? 'flex-end' : 'flex-start'); }, function (props) {
    return !props.fluid &&
        Object.keys(breakpoints).map(function (key) {
            if (!breakpoints[key].mediaQuery) {
                return null;
            }
            return css(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n        @media (", ") {\n          max-width: ", "px;\n        }\n      "], ["\n        @media (", ") {\n          max-width: ", "px;\n        }\n      "])), breakpoints[key].mediaQuery, breakpoints[key].containerWidthPixels);
        });
});
var templateObject_1, templateObject_2;
