var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
import React from 'react';
import { FaSync } from 'react-icons/fa';
import styled, { keyframes } from 'styled-components';
var DEFAULT_PROFILE_IMAGE_SIZE = 5;
export var ProfileImage = function (_a) {
    var user = _a.user, _b = _a.size, size = _b === void 0 ? DEFAULT_PROFILE_IMAGE_SIZE : _b, onClick = _a.onClick;
    return (React.createElement(Container, { onClick: onClick, size: size }, user ? (React.createElement(UserImage, { src: user.picture_url, title: user.name, referrerPolicy: "no-referrer" })) : (React.createElement(Placeholder, null))));
};
var Container = styled.div(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  display: flex;\n  align-items: center;\n  justify-content: center;\n  flex: 0 0 ", "rem;\n  width: ", "rem;\n  height: ", "rem;\n  border-radius: 50%;\n  overflow: hidden;\n  background: #ccc;\n  cursor: pointer;\n"], ["\n  display: flex;\n  align-items: center;\n  justify-content: center;\n  flex: 0 0 ", "rem;\n  width: ", "rem;\n  height: ", "rem;\n  border-radius: 50%;\n  overflow: hidden;\n  background: #ccc;\n  cursor: pointer;\n"])), function (_a) {
    var size = _a.size;
    return size;
}, function (_a) {
    var size = _a.size;
    return size;
}, function (_a) {
    var size = _a.size;
    return size;
});
var UserImage = styled.img(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n  width: 100%;\n  height: 100%;\n  margin: 0;\n  display: block;\n"], ["\n  width: 100%;\n  height: 100%;\n  margin: 0;\n  display: block;\n"])));
var PlaceholderAnim = keyframes(templateObject_3 || (templateObject_3 = __makeTemplateObject(["\n  0% {\n    transform: rotate(0deg);\n  }\n  100% {\n    transform: rotate(360deg);\n  }\n"], ["\n  0% {\n    transform: rotate(0deg);\n  }\n  100% {\n    transform: rotate(360deg);\n  }\n"])));
var Placeholder = styled(FaSync)(templateObject_4 || (templateObject_4 = __makeTemplateObject(["\n  width: ", "rem;\n  color: #999;\n  animation: ", " 2s linear 0s infinite;\n"], ["\n  width: ", "rem;\n  color: #999;\n  animation: ", " 2s linear 0s infinite;\n"])), function (_a) {
    var _b = _a.size, size = _b === void 0 ? DEFAULT_PROFILE_IMAGE_SIZE : _b;
    return size / 2;
}, PlaceholderAnim);
var templateObject_1, templateObject_2, templateObject_3, templateObject_4;
