var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
import React, { useContext, useEffect, useState } from 'react';
import { observer } from 'mobx-react-lite';
import { useLocation, useNavigate } from 'react-router-dom';
import { FaTimes } from 'react-icons/fa';
import styled from 'styled-components';
import qs from 'query-string';
import { AppContext } from 'store';
import { QLogoIcon } from 'app/components/icons';
import { ProfileImage } from 'app/components/profile-image';
import analytics, { GTMEventType, GTMEventGategory } from 'app/utils/analytics';
import { getSearchParamsFromQuery } from 'app/utils';
import { SearchField } from './search-field';
var HeaderComponent = function (_a) {
    var showProfileIcon = _a.showProfileIcon;
    var navigate = useNavigate();
    var routerLocation = useLocation();
    var _b = useContext(AppContext), userStore = _b.userStore, portalStore = _b.portalStore;
    var _c = useState({ query: '' }), search = _c[0], setSearch = _c[1];
    useEffect(function () {
        setSearch(getSearchParamsFromQuery(routerLocation.search));
    }, []);
    var onLogoClick = function () {
        portalStore.setEditMode(false);
        navigate('/');
    };
    var onExecuteSearch = function (value) {
        var query = value.query, type = value.type, id = value.id, team = value.team, location = value.location;
        if (query.length) {
            analytics.triggerEvent(GTMEventType.SearchExecute, {
                category: GTMEventGategory.Search,
                label: query,
            });
            portalStore.setEditMode(false);
            var queryParams = {
                search: query,
                type: type,
            };
            if (id) {
                queryParams.id = id;
            }
            if (team) {
                queryParams.team = team;
            }
            if (location) {
                queryParams.location = location;
            }
            var queryString = qs.stringify(queryParams);
            navigate("/?".concat(queryString));
        }
        else {
            // Empty search string resets listing
            navigate('/');
        }
    };
    var onProfileImageClick = function () {
        navigate('/profile');
    };
    var onCloseClick = function () {
        portalStore.setEditMode(false);
        navigate('/');
    };
    var renderTopRightIcon = function () {
        if (showProfileIcon) {
            return (userStore.user && (React.createElement(ProfileImage, { user: userStore.user, size: 5, onClick: onProfileImageClick })));
        }
        return (React.createElement(CloseButton, { onClick: onCloseClick },
            React.createElement(CloseIcon, null)));
    };
    return (React.createElement(React.Fragment, null,
        React.createElement(Container, null,
            React.createElement(Logo, { onClick: onLogoClick }),
            React.createElement(SearchFieldWrapper, null,
                React.createElement(SearchField, { value: search, onExecuteSearch: onExecuteSearch, placeholder: "Search for a person, expertise or tech" })),
            renderTopRightIcon())));
};
var Container = styled.div(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  display: flex;\n  flex-direction: row;\n  align-items: center;\n  padding: 1rem 1.3rem;\n  justify-content: space-between;\n  background-color: ", ";\n"], ["\n  display: flex;\n  flex-direction: row;\n  align-items: center;\n  padding: 1rem 1.3rem;\n  justify-content: space-between;\n  background-color: ", ";\n"])), function (_a) {
    var theme = _a.theme;
    return theme.color.bgCard;
});
var Logo = styled(QLogoIcon)(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n  height: 5rem;\n  cursor: pointer;\n  fill: ", ";\n"], ["\n  height: 5rem;\n  cursor: pointer;\n  fill: ", ";\n"])), function (_a) {
    var theme = _a.theme;
    return theme.color.txt1;
});
var SearchFieldWrapper = styled.div(templateObject_3 || (templateObject_3 = __makeTemplateObject(["\n  padding: 0 1rem;\n  flex: 1;\n"], ["\n  padding: 0 1rem;\n  flex: 1;\n"])));
var CloseButton = styled.button(templateObject_4 || (templateObject_4 = __makeTemplateObject(["\n  cursor: pointer;\n  display: flex;\n  align-items: center;\n  justify-content: center;\n  border: 0;\n  background: transparent;\n  height: 5rem;\n  width: 5rem;\n  font-size: 3rem;\n"], ["\n  cursor: pointer;\n  display: flex;\n  align-items: center;\n  justify-content: center;\n  border: 0;\n  background: transparent;\n  height: 5rem;\n  width: 5rem;\n  font-size: 3rem;\n"])));
var CloseIcon = styled(FaTimes)(templateObject_5 || (templateObject_5 = __makeTemplateObject(["\n  color: ", ";\n"], ["\n  color: ", ";\n"])), function (_a) {
    var theme = _a.theme;
    return theme.color.txt1;
});
export var Header = observer(HeaderComponent);
var templateObject_1, templateObject_2, templateObject_3, templateObject_4, templateObject_5;
