import { useState, useEffect } from 'react';
/**
 * Returns browser detected online status.
 * This is a hook that adds and removes event listeners.
 *
 * @returns {{isOnline: boolean}} True for online, false for offline.
 */
export var useOnlineStatus = function () {
    var _a = useState(navigator.onLine), online = _a[0], setOnline = _a[1];
    useEffect(function () {
        window.addEventListener('online', handler);
        window.addEventListener('offline', handler);
        // cleanup
        return function () {
            window.removeEventListener('online', handler);
            window.removeEventListener('offline', handler);
        };
    });
    function handler() {
        setOnline(navigator.onLine);
    }
    return { isOnline: online };
};
