var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import TagManager from 'react-gtm-module';
var TAG_MANAGER_SETTINGS = {
    gtmId: process.env.GTM_ID,
    /**
     * auth and preview fields can be used when testing GTM in preview mode
     * See: https://support.google.com/tagmanager/answer/6107056?hl=en
     *
     * auth: process.env.GTM_AUTH,
     * preview: process.env.GTM_PREVIEW,
     *
     */
};
var DEFAULT_EVENT = 'qevent';
var Analytics = /** @class */ (function () {
    function Analytics() {
        Object.defineProperty(this, "dataLayer", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
        Object.defineProperty(this, "isAnalyticsEnabled", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: !!TAG_MANAGER_SETTINGS.gtmId
        });
        Object.defineProperty(this, "user", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: null
        });
    }
    Object.defineProperty(Analytics.prototype, "initialize", {
        enumerable: false,
        configurable: true,
        writable: true,
        value: function () {
            if (this.isAnalyticsEnabled) {
                TagManager.initialize(TAG_MANAGER_SETTINGS);
                this.dataLayer = window.dataLayer || [];
            }
        }
    });
    Object.defineProperty(Analytics.prototype, "setUser", {
        enumerable: false,
        configurable: true,
        writable: true,
        value: function (user) {
            this.user = user;
        }
    });
    Object.defineProperty(Analytics.prototype, "triggerEvent", {
        enumerable: false,
        configurable: true,
        writable: true,
        value: function (eventType, event) {
            if (this.isAnalyticsEnabled && this.user) {
                /**
                 * Pass user role with action so it can
                 * be filtered in analytics
                 */
                var actionWithRole = this.user.role ? "".concat(this.user.role, ":").concat(eventType) : eventType;
                this.dataLayer.push(__assign({ event: DEFAULT_EVENT, action: actionWithRole }, event));
            }
        }
    });
    return Analytics;
}());
export var GTMEventType;
(function (GTMEventType) {
    GTMEventType["ListTypeChange"] = "listTypeChange";
    GTMEventType["ProfileEdit"] = "profileEdit";
    GTMEventType["ProfileSave"] = "profileSave";
    GTMEventType["ProfileCancelEdit"] = "profileCancelEdit";
    GTMEventType["ProfileView"] = "profileView";
    GTMEventType["ProfileViewOwn"] = "profileViewOwn";
    GTMEventType["SearchExecute"] = "searchExecute";
    GTMEventType["SignIn"] = "signIn";
    GTMEventType["SignOut"] = "signOut";
    GTMEventType["ThemeChange"] = "themeChange";
})(GTMEventType || (GTMEventType = {}));
export var GTMEventGategory;
(function (GTMEventGategory) {
    GTMEventGategory["Profile"] = "profile";
    GTMEventGategory["Search"] = "search";
    GTMEventGategory["User"] = "user";
})(GTMEventGategory || (GTMEventGategory = {}));
export default new Analytics();
