var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
import React, { useEffect, useRef } from 'react';
import { observer } from 'mobx-react-lite';
import styled from 'styled-components';
import { bindUi } from 'app/utils/firebase';
import { QLogoIcon } from 'app/components';
var LoginComponent = function () {
    var el = useRef(null);
    useEffect(function () {
        if (el.current) {
            bindUi(el.current);
        }
    }, [el.current]);
    return (React.createElement(LoginContainer, { ref: el },
        React.createElement(Logo, null)));
};
var LoginContainer = styled.div(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  margin-top: 30vh;\n"], ["\n  margin-top: 30vh;\n"])));
var Logo = styled(QLogoIcon)(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n  display: block;\n  margin-left: auto;\n  margin-right: auto;\n  width: 15rem;\n  margin-bottom: 3rem;\n  fill: ", ";\n"], ["\n  display: block;\n  margin-left: auto;\n  margin-right: auto;\n  width: 15rem;\n  margin-bottom: 3rem;\n  fill: ", ";\n"])), function (_a) {
    var theme = _a.theme;
    return theme.color.txt1;
});
export var LoginView = observer(LoginComponent);
var templateObject_1, templateObject_2;
