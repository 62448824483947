var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import React from 'react';
import styled, { useTheme } from 'styled-components';
import { FaMobileAlt, FaRegEnvelope, FaEllipsisH } from 'react-icons/fa';
import Highlighter from 'react-highlight-words';
import { ProfileImage, Skill } from 'app/components';
import { SearchResultMatchType } from 'app/types';
import { SkillLevel, SearchItemType } from 'app/constants';
var MAX_VISIBLE_SKILLS = 5;
var SearchResultItemComponent = function (_a) {
    var currentSearchItem = _a.currentSearchItem, onItemClick = _a.onItemClick, onSkillClick = _a.onSkillClick, resultItem = _a.resultItem;
    var theme = useTheme();
    var userSkills = resultItem.skills || [];
    var itemMatches = resultItem.matches || [];
    var highlightElems = {
        name: itemMatches.filter(function (match) { return match.matchType === SearchResultMatchType.UserName; })
            .length > 0,
        title: itemMatches.filter(function (match) { return match.matchType === SearchResultMatchType.UserTitle; })
            .length > 0,
        phone: itemMatches.filter(function (match) { return match.matchType === SearchResultMatchType.UserPhone; })
            .length > 0,
    };
    var mapSkills = function () {
        var _a, _b;
        var mappedSkills = {
            fluentCount: userSkills.filter(function (skill) { return skill.score === SkillLevel.Fluent; }).length,
            goodCount: userSkills.filter(function (skill) { return skill.score === SkillLevel.Good; }).length,
            basicsCount: userSkills.filter(function (skill) { return skill.score === SkillLevel.Basics; }).length,
            matched: (_a = {},
                _a[SkillLevel.Fluent] = [],
                _a[SkillLevel.Good] = [],
                _a[SkillLevel.Basics] = [],
                _a),
            notMatched: (_b = {},
                _b[SkillLevel.Fluent] = [],
                _b[SkillLevel.Good] = [],
                _b[SkillLevel.Basics] = [],
                _b),
        };
        // If we have search string
        if (currentSearchItem.query.length > 0 && itemMatches.length > 0) {
            var matchedSkillIdList_1 = [];
            // Create list of matched skill ids
            itemMatches.forEach(function (item) {
                if (item.matchType === SearchResultMatchType.Skill) {
                    matchedSkillIdList_1.push(item.matchId);
                }
            });
            userSkills.forEach(function (skill) {
                var matchFound = matchedSkillIdList_1.indexOf(skill.id) > -1;
                if (matchFound) {
                    /** Get match details */
                    var match = itemMatches.filter(function (value) { return value.matchId === skill.id; }).pop();
                    if (match) {
                        var highlightedSkill = __assign(__assign({}, skill), { highlight: currentSearchItem.type === SearchItemType.Subcategory
                                ? skill.name // If search made with subcategory, highlight all skills
                                : currentSearchItem.query, priority: match.matchDetails.index });
                        mappedSkills.matched[skill.score].push(highlightedSkill);
                    }
                }
                else {
                    mappedSkills.notMatched[skill.score].push(skill);
                }
            });
        }
        else {
            /** No search made currently, no need for filterings */
            userSkills.forEach(function (skill) {
                mappedSkills.notMatched[skill.score].push(skill);
            });
        }
        /** Place skills that matches from beginning of the word before other matches */
        mappedSkills.matched[SkillLevel.Fluent].sort(function (a, b) {
            return a.priority && b.priority ? a.priority - b.priority : 0;
        });
        mappedSkills.matched[SkillLevel.Good].sort(function (a, b) {
            return a.priority && b.priority ? a.priority - b.priority : 0;
        });
        mappedSkills.matched[SkillLevel.Basics].sort(function (a, b) {
            return a.priority && b.priority ? a.priority - b.priority : 0;
        });
        return mappedSkills;
    };
    var renderSkills = function () {
        var skillElems = [];
        var mappedSkills = mapSkills();
        var renderSkill = function (skill) {
            return (React.createElement(StyledSkill, { key: skill.id, skill: __assign(__assign({}, skill), { score: 0 }), editable: false, highlight: skill.highlight, onClick: onSkillClick, color: theme.color.txt1 }));
        };
        if (mappedSkills.fluentCount > 0) {
            var visibleSkills = [];
            // If search made..
            if (currentSearchItem.query.length > 0) {
                // and we found matched skills..
                if (mappedSkills.matched[SkillLevel.Fluent].length > 0) {
                    visibleSkills.push.apply(visibleSkills, mappedSkills.matched[SkillLevel.Fluent]);
                }
            }
            else {
                visibleSkills.push.apply(visibleSkills, mappedSkills.notMatched[SkillLevel.Fluent]);
            }
            if (visibleSkills.length > 0) {
                skillElems.push(React.createElement(React.Fragment, { key: "fluent-skills" },
                    React.createElement(Subtitle, null, "Fluent in"),
                    React.createElement(SkillsContainer, null,
                        visibleSkills
                            .slice(0, MAX_VISIBLE_SKILLS)
                            .map(function (skill) { return renderSkill(skill); }),
                        visibleSkills.length > MAX_VISIBLE_SKILLS && React.createElement(OverflowIndicator, null))));
            }
        }
        /** If we have matched skills in level "Good" */
        if (mappedSkills.matched[SkillLevel.Good].length > 0) {
            var visibleSkills = mappedSkills.matched[SkillLevel.Good].slice(0, MAX_VISIBLE_SKILLS);
            skillElems.push(React.createElement(React.Fragment, { key: "good-skills" },
                React.createElement(Subtitle, null, "Good in"),
                React.createElement(SkillsContainer, null, visibleSkills.map(function (skill) { return renderSkill(skill); }))));
        }
        /** If we have matched skills in level "Basics" */
        if (mappedSkills.matched[SkillLevel.Basics].length > 0) {
            var visibleSkills = mappedSkills.matched[SkillLevel.Basics].slice(0, MAX_VISIBLE_SKILLS);
            skillElems.push(React.createElement(React.Fragment, { key: "basic-skills" },
                React.createElement(Subtitle, null, "Basics in"),
                React.createElement(SkillsContainer, null, visibleSkills.map(function (skill) { return renderSkill(skill); }))));
        }
        return skillElems;
    };
    return (React.createElement(Container, { onClick: function () { return onItemClick(resultItem); } },
        React.createElement(PersonContainer, null,
            React.createElement(ProfileImage, { user: resultItem, size: 16 }),
            React.createElement(PersonDetailsContainer, null,
                React.createElement(Name, null,
                    React.createElement(Highlighter, { highlightClassName: "highlighted-text", searchWords: highlightElems.name ? [currentSearchItem.query] : [], autoEscape: true, textToHighlight: resultItem.name })),
                resultItem.title && (React.createElement(Title, null,
                    React.createElement(Highlighter, { highlightClassName: "highlighted-text", searchWords: highlightElems.title ? [currentSearchItem.query] : [], autoEscape: true, textToHighlight: resultItem.title }))),
                resultItem.phone_number && (React.createElement(ContactInfo, null,
                    React.createElement(FaMobileAlt, null),
                    React.createElement(ContactInfoValue, null,
                        React.createElement(Highlighter, { highlightClassName: "highlighted-text", searchWords: highlightElems.phone ? [currentSearchItem.query] : [], autoEscape: true, textToHighlight: resultItem.phone_number })))),
                React.createElement(ContactInfo, null,
                    React.createElement(FaRegEnvelope, null),
                    React.createElement(ContactInfoValue, null, resultItem.email)),
                renderSkills()))));
};
var Container = styled.div(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  background: ", ";\n  border-radius: ", ";\n  box-shadow: 0 0 1rem rgba(0, 0, 0, 0.1);\n  padding: 2rem;\n  cursor: pointer;\n\n  ", "\n\n  ", "\n"], ["\n  background: ", ";\n  border-radius: ", ";\n  box-shadow: 0 0 1rem rgba(0, 0, 0, 0.1);\n  padding: 2rem;\n  cursor: pointer;\n\n  ", "\n\n  ", "\n"])), function (_a) {
    var theme = _a.theme;
    return theme.color.bgCard;
}, function (_a) {
    var theme = _a.theme;
    return theme.borderRadius.medium;
}, function (_a) {
    var theme = _a.theme;
    return theme.onMobile("\n      border-radius: 0;\n    ");
}, function (_a) {
    var theme = _a.theme;
    return theme.onExtraLargeDesktop("\n      min-height: 32rem;\n    ");
});
var SkillsContainer = styled.div(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n  display: flex;\n  align-items: center;\n  flex-wrap: wrap;\n  margin-top: 0.5rem;\n"], ["\n  display: flex;\n  align-items: center;\n  flex-wrap: wrap;\n  margin-top: 0.5rem;\n"])));
var StyledSkill = styled(Skill)(templateObject_3 || (templateObject_3 = __makeTemplateObject(["\n  margin: 0.3rem 0.5rem 0.3rem 0;\n\n  button {\n    padding: 0.2rem 0.6rem;\n    font-size: 1.2rem;\n    height: auto;\n  }\n"], ["\n  margin: 0.3rem 0.5rem 0.3rem 0;\n\n  button {\n    padding: 0.2rem 0.6rem;\n    font-size: 1.2rem;\n    height: auto;\n  }\n"])));
var PersonContainer = styled.div(templateObject_4 || (templateObject_4 = __makeTemplateObject(["\n  display: flex;\n  flex-direction: row;\n  align-items: flex-start;\n"], ["\n  display: flex;\n  flex-direction: row;\n  align-items: flex-start;\n"])));
var PersonDetailsContainer = styled.div(templateObject_5 || (templateObject_5 = __makeTemplateObject(["\n  display: flex;\n  flex-direction: column;\n  padding: 0 20px;\n"], ["\n  display: flex;\n  flex-direction: column;\n  padding: 0 20px;\n"])));
var Name = styled.h3(templateObject_6 || (templateObject_6 = __makeTemplateObject(["\n  margin-block-start: 0;\n  margin-block-end: 0;\n  color: ", ";\n  font-family: ", ";\n  font-size: 3rem;\n  font-weight: 400;\n  line-height: 3rem;\n  text-overflow: ellipsis;\n  margin: 0 0 1rem;\n"], ["\n  margin-block-start: 0;\n  margin-block-end: 0;\n  color: ", ";\n  font-family: ", ";\n  font-size: 3rem;\n  font-weight: 400;\n  line-height: 3rem;\n  text-overflow: ellipsis;\n  margin: 0 0 1rem;\n"])), function (_a) {
    var theme = _a.theme;
    return theme.color.txt1;
}, function (_a) {
    var theme = _a.theme;
    return theme.font.primary;
});
var Title = styled.p(templateObject_7 || (templateObject_7 = __makeTemplateObject(["\n  text-transform: uppercase;\n  font-family: ", ";\n  font-weight: 800;\n  letter-spacing: 0.25rem;\n  font-size: 1.2rem;\n  text-overflow: ellipsis;\n  margin: 0 0 1rem;\n"], ["\n  text-transform: uppercase;\n  font-family: ", ";\n  font-weight: 800;\n  letter-spacing: 0.25rem;\n  font-size: 1.2rem;\n  text-overflow: ellipsis;\n  margin: 0 0 1rem;\n"])), function (_a) {
    var theme = _a.theme;
    return theme.font.secondary;
});
var ContactInfo = styled.p(templateObject_8 || (templateObject_8 = __makeTemplateObject(["\n  display: inline-flex;\n  align-items: center;\n  font-family: ", ";\n  font-size: 1.6rem;\n  font-weight: 500;\n  margin: 0.35rem 0;\n"], ["\n  display: inline-flex;\n  align-items: center;\n  font-family: ", ";\n  font-size: 1.6rem;\n  font-weight: 500;\n  margin: 0.35rem 0;\n"])), function (_a) {
    var theme = _a.theme;
    return theme.font.primary;
});
var Subtitle = styled.h3(templateObject_9 || (templateObject_9 = __makeTemplateObject(["\n  margin-bottom: 0;\n  font-size: 1.6rem;\n  font-weight: normal;\n  font-family: ", ";\n  color: ", ";\n"], ["\n  margin-bottom: 0;\n  font-size: 1.6rem;\n  font-weight: normal;\n  font-family: ", ";\n  color: ", ";\n"])), function (_a) {
    var theme = _a.theme;
    return theme.font.primary;
}, function (_a) {
    var theme = _a.theme;
    return theme.color.txt1;
});
var ContactInfoValue = styled.span(templateObject_10 || (templateObject_10 = __makeTemplateObject(["\n  margin-left: 7px;\n  word-break: break-all;\n"], ["\n  margin-left: 7px;\n  word-break: break-all;\n"])));
var OverflowIndicator = styled(FaEllipsisH)(templateObject_11 || (templateObject_11 = __makeTemplateObject(["\n  margin-left: 0.2rem;\n"], ["\n  margin-left: 0.2rem;\n"])));
export var SearchResultItem = SearchResultItemComponent;
var templateObject_1, templateObject_2, templateObject_3, templateObject_4, templateObject_5, templateObject_6, templateObject_7, templateObject_8, templateObject_9, templateObject_10, templateObject_11;
