import React from 'react';
import { userStore } from './user';
import { dataStore } from './data';
import { portalStore } from './portal';
export var AppContext = React.createContext({
    userStore: userStore,
    dataStore: dataStore,
    portalStore: portalStore,
});
export * from './user';
export * from './data';
export * from './portal';
