var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
import React from 'react';
import styled from 'styled-components';
export var SearchResultTitle = function (_a) {
    var title = _a.title;
    return (React.createElement(Container, null,
        React.createElement("span", null, title)));
};
var Container = styled.div(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  padding-left: 1.5rem;\n  margin: 1.5rem auto;\n  span {\n    display: inline-block;\n    line-height: 3rem;\n    color: ", ";\n    font-weight: 400;\n    font-size: 1.6rem;\n    font-family: ", ";\n  }\n"], ["\n  padding-left: 1.5rem;\n  margin: 1.5rem auto;\n  span {\n    display: inline-block;\n    line-height: 3rem;\n    color: ", ";\n    font-weight: 400;\n    font-size: 1.6rem;\n    font-family: ", ";\n  }\n"])), function (_a) {
    var theme = _a.theme;
    return theme.color.txt2;
}, function (_a) {
    var theme = _a.theme;
    return theme.font.primary;
});
var templateObject_1;
