var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __metadata = (this && this.__metadata) || function (k, v) {
    if (typeof Reflect === "object" && typeof Reflect.metadata === "function") return Reflect.metadata(k, v);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
import { action, computed, observable, runInAction, makeObservable } from 'mobx';
import { matchAll, sortArrayByField } from 'app/utils';
import { DataType, SearchItemType, SortOrder } from '../constants';
import { SearchResultMatchType, } from '../types';
import { userApi, categoryApi, subcategoryApi, skillApi, searchApi } from '../api';
import DataStorage from './data-storage';
export var DataStore = /** @class */ (function () {
    function DataStore() {
        var _this = this;
        Object.defineProperty(this, "users", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: []
        });
        Object.defineProperty(this, "userSkills", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: { categories: [] }
        });
        Object.defineProperty(this, "editableUserSkills", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: []
        });
        Object.defineProperty(this, "searchTags", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: []
        });
        Object.defineProperty(this, "allCategories", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: []
        });
        Object.defineProperty(this, "allSubcategories", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: []
        });
        Object.defineProperty(this, "allSkills", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: []
        });
        Object.defineProperty(this, "recentSearches", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: []
        });
        Object.defineProperty(this, "currentSearchItem", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: { query: '', type: SearchItemType.Other }
        });
        Object.defineProperty(this, "noResultsWithSearch", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: ''
        });
        Object.defineProperty(this, "loading", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: false
        });
        Object.defineProperty(this, "searchResults", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: []
        });
        /* Cache fetched data for 10 minutes */
        Object.defineProperty(this, "CACHE_TIME", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: 1000 * 60 * 10
        });
        Object.defineProperty(this, "setSearchResults", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: function (value) {
                _this.searchResults = value;
            }
        });
        Object.defineProperty(this, "executeSearch", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: function () {
                var results = [];
                // Empty search => return all users
                if (_this.currentSearchItem.query.length === 0) {
                    // Add empty "matches"
                    var mappedUsers = _this.users.map(function (user) { return (__assign(__assign({}, user), { matches: [], matchPoints: 0 })); });
                    results.push.apply(results, mappedUsers);
                    results = _this.filterResultsByTeam(results);
                    results = _this.filterResultsByLocation(results);
                    // Sort by name
                    results.sort(function (a, b) { return a.name.localeCompare(b.name); });
                    _this.setSearchResults(results);
                    return results;
                }
                switch (_this.currentSearchItem.type) {
                    case SearchItemType.Subcategory:
                        results = _this.getSearchResultBySubcategoryId(_this.currentSearchItem.id || 0);
                        break;
                    case SearchItemType.Skill:
                        results = _this.getSearchResultBySkillId(_this.currentSearchItem.id || 0);
                        break;
                    default:
                        results = _this.getSearchResultByText(_this.currentSearchItem.query);
                }
                results = _this.filterResultsByTeam(results);
                results = _this.filterResultsByLocation(results);
                // Calculate total match points per item
                results.forEach(function (res, idx) {
                    if (!res.matches)
                        return;
                    var totalMatchPoints = res.matches.reduce(function (acc, cur) { return acc + cur.points; }, 0);
                    results[idx].matchPoints = totalMatchPoints + res.matches.length;
                });
                // Sort results by match points
                results.sort(function (a, b) { return b.matchPoints - a.matchPoints; });
                _this.setSearchResults(results);
                return results;
            }
        });
        Object.defineProperty(this, "filterResultsByTeam", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: function (results) {
                return _this.currentSearchItem.team
                    ? results.filter(function (user) {
                        return (_this.currentSearchItem.team && user.team.includes(_this.currentSearchItem.team));
                    })
                    : results;
            }
        });
        Object.defineProperty(this, "filterResultsByLocation", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: function (results) {
                return _this.currentSearchItem.location
                    ? results.filter(function (user) {
                        return (_this.currentSearchItem.location &&
                            user.location.includes(_this.currentSearchItem.location));
                    })
                    : results;
            }
        });
        Object.defineProperty(this, "getAllUserTeams", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: function () {
                var teams = [];
                teams.push({ key: 'all teams', value: _this.users.length });
                var collectedTeams = _this.users
                    // go through all user teams
                    .map(function (user) {
                    if (!user.team) {
                        return [];
                    }
                    return user.team.split(',').map(function (item) { return item.trim(); });
                })
                    // multi-team users are returned as arrays, flatten
                    .flat()
                    .sort()
                    .reduce(function (acc, curr) {
                    if (!acc[curr]) {
                        acc[curr] = 1;
                    }
                    else {
                        acc[curr] += 1;
                    }
                    return acc;
                }, {});
                teams.push.apply(teams, Object.keys(collectedTeams)
                    .sort()
                    .map(function (curr) { return ({ key: curr, value: collectedTeams[curr] }); }));
                return teams;
            }
        });
        Object.defineProperty(this, "getAllUserLocations", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: function () {
                var locations = [];
                locations.push({ key: 'all locations', value: _this.users.length });
                var collectedLocations = _this.users
                    // go through all user locations
                    .map(function (user) { return user.location; })
                    .filter(function (location) { return !!location; })
                    .reduce(function (acc, curr) {
                    if (!acc[curr]) {
                        acc[curr] = 1;
                    }
                    else {
                        acc[curr] += 1;
                    }
                    return acc;
                }, {});
                locations.push.apply(locations, Object.keys(collectedLocations)
                    .sort()
                    .map(function (curr) { return ({ key: curr, value: collectedLocations[curr] }); }));
                return locations;
            }
        });
        Object.defineProperty(this, "getSearchResultBySkillId", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: function (skillId) {
                var results = [];
                // Loop through users to check matches for each user search criteria
                _this.users.forEach(function (user) {
                    var resultItem = __assign(__assign({}, user), { matches: [], matchPoints: 0 });
                    // Match user skill
                    user.skills.forEach(function (skill, idx) {
                        if (skill.id === skillId && resultItem.matches) {
                            resultItem.matches.push({
                                matchId: skill.id,
                                matchType: SearchResultMatchType.Skill,
                                matchDetails: { index: 0, length: skill.name.length, order: idx },
                                points: skill.score,
                            });
                        }
                    });
                    if (resultItem.matches && resultItem.matches.length > 0) {
                        results.push(resultItem);
                    }
                });
                return results;
            }
        });
        Object.defineProperty(this, "getSearchResultBySubcategoryId", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: function (subcategoryId) {
                var results = [];
                var skillIdsInSubcategory = _this.allSkills
                    .filter(function (skill) { return skill.subcategory_id === subcategoryId; })
                    .map(function (skill) { return skill.id; });
                _this.users.forEach(function (user) {
                    var resultItem = __assign(__assign({}, user), { matches: [], matchPoints: 0 });
                    var matchedSkills = user.skills.filter(function (skill) {
                        return skillIdsInSubcategory.includes(skill.id);
                    });
                    matchedSkills.forEach(function (skill, idx) {
                        if (resultItem.matches) {
                            resultItem.matches.push({
                                matchId: skill.id,
                                matchType: SearchResultMatchType.Skill,
                                matchDetails: { index: 0, length: skill.name.length, order: idx },
                                points: skill.score,
                            });
                        }
                    });
                    if (resultItem.matches && resultItem.matches.length > 0) {
                        results.push(resultItem);
                    }
                });
                return results;
            }
        });
        Object.defineProperty(this, "getSearchResultByText", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: function (searchStr) {
                var results = [];
                var noMatch = { index: 0, length: 0, order: 0 };
                // Loop through users to check matches for each user search criteria
                _this.users.forEach(function (user) {
                    var resultItem = __assign(__assign({}, user), { matches: [], matchPoints: 0 });
                    var strMatch;
                    // Match user name
                    if ((strMatch = matchAll(searchStr, user.name)) && resultItem.matches) {
                        var firstMatch = strMatch.pop();
                        if (firstMatch) {
                            resultItem.matches.push({
                                matchId: user.id,
                                matchType: SearchResultMatchType.UserName,
                                matchDetails: firstMatch || noMatch,
                                points: 200 - firstMatch.index,
                            });
                        }
                    }
                    // Match user title
                    if ((strMatch = matchAll(searchStr, user.title)) && resultItem.matches) {
                        var firstMatch = strMatch.pop();
                        if (firstMatch) {
                            resultItem.matches.push({
                                matchId: user.id,
                                matchType: SearchResultMatchType.UserTitle,
                                matchDetails: firstMatch || noMatch,
                                points: 100 - firstMatch.index,
                            });
                        }
                    }
                    // Match user phone
                    if ((strMatch = matchAll(searchStr, user.phone_number)) && resultItem.matches) {
                        var firstMatch = strMatch.pop();
                        if (firstMatch) {
                            resultItem.matches.push({
                                matchId: user.id,
                                matchType: SearchResultMatchType.UserPhone,
                                matchDetails: firstMatch || noMatch,
                                points: 50 - firstMatch.index,
                            });
                        }
                    }
                    // Match user skill
                    user.skills.forEach(function (skill) {
                        if ((strMatch = matchAll(searchStr, skill.name)) && resultItem.matches) {
                            var firstMatch = strMatch.pop();
                            resultItem.matches.push({
                                matchId: skill.id,
                                matchType: SearchResultMatchType.Skill,
                                matchDetails: firstMatch || noMatch,
                                points: skill.score,
                            });
                        }
                    });
                    if (resultItem.matches && resultItem.matches.length > 0) {
                        results.push(resultItem);
                    }
                });
                return results;
            }
        });
        Object.defineProperty(this, "setCurrentSearchItem", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: function (value) {
                _this.currentSearchItem = value;
            }
        });
        Object.defineProperty(this, "getSuggestions", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: function (searchStr) {
                var suggestions = [];
                var skillLevelMapping = { 1: 'basic', 2: 'good', 3: 'fluent' };
                var matches;
                if (!searchStr.length) {
                    return [];
                }
                var getSubcategoryById = function (categoryId) {
                    var founded = _this.allSubcategories.filter(function (category) { return category.id === categoryId; });
                    return founded.length ? founded[0] : null;
                };
                var getCategoryNameById = function (categoryId) {
                    var founded = _this.allCategories.filter(function (category) { return category.id === categoryId; });
                    return founded.length ? founded[0].name : null;
                };
                _this.users.forEach(function (user) {
                    // Add all users with matching details
                    var matchesName;
                    if ((matchesName = matchAll(searchStr, user.name)) !== null ||
                        (matches = matchAll(searchStr, user.phone_number)) !== null ||
                        (matches = matchAll(searchStr, user.title)) !== null) {
                        var description = '';
                        var highlightField = matchesName ? 'title' : 'description';
                        var matchOrder = matchesName ? matchesName[0].order : matches[0].order;
                        description += user.title ? "Title: ".concat(user.title) : '';
                        description += user.phone_number ? ", Phone: ".concat(user.phone_number) : '';
                        suggestions.push({
                            id: user.id,
                            title: user.name,
                            description: description,
                            type: SearchItemType.Person,
                            priority: 1 + matchOrder,
                            highlight: { field: highlightField, words: [searchStr] },
                        });
                    }
                    // Add all users with matching skill name
                    user.skills.forEach(function (skill) {
                        if ((matches = matchAll(searchStr, skill.name)) !== null) {
                            var existingUserRows = suggestions.filter(function (suggestion) { return suggestion.id === user.id; });
                            if (existingUserRows.length === 0) {
                                suggestions.push({
                                    id: user.id,
                                    title: user.name,
                                    description: "Has ".concat(skillLevelMapping[skill.score], " skills in ").concat(skill.name),
                                    type: SearchItemType.Person,
                                    priority: 3 + matches[0].order + (3 - skill.score),
                                    highlight: { field: 'description', words: [searchStr] },
                                });
                            }
                        }
                    });
                });
                // Add skills with partial matching name
                _this.allSkills.forEach(function (skill) {
                    var subcategory = getSubcategoryById(skill.subcategory_id);
                    if (subcategory && (matches = matchAll(searchStr, skill.name)) !== null) {
                        suggestions.push({
                            id: skill.id,
                            title: skill.name,
                            description: "in subcategory ".concat(subcategory.name),
                            type: SearchItemType.Skill,
                            priority: 1 + matches[0].order,
                            highlight: { field: 'title', words: [searchStr] },
                        });
                    }
                    // Add matching subcategories to the list of suggestions
                    if (subcategory &&
                        (matches = matchAll(searchStr, subcategory.name)) !== null &&
                        !suggestions.some(function (suggestion) { return suggestion.id === skill.subcategory_id; })) {
                        var categoryName = getCategoryNameById(subcategory.category_id);
                        suggestions.push({
                            id: skill.subcategory_id,
                            title: subcategory.name,
                            description: "in category ".concat(categoryName),
                            type: SearchItemType.Subcategory,
                            priority: 2 + matches[0].order,
                            highlight: { field: 'title', words: [searchStr] },
                        });
                    }
                });
                sortArrayByField(suggestions, 'priority', SortOrder.Ascending);
                return __spreadArray([], suggestions.slice(0, 10), true);
            }
        });
        Object.defineProperty(this, "setNoResultsWithString", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: function (searchValue) {
                _this.noResultsWithSearch = searchValue;
            }
        });
        // Search tag actions are currently not used
        Object.defineProperty(this, "addSearchTag", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: function (tag) {
                _this.searchTags = _this.searchTags.concat([tag]);
            }
        });
        Object.defineProperty(this, "removeSearchTag", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: function (idx) {
                _this.searchTags = _this.searchTags.filter(function (tag, index) {
                    return index !== idx;
                });
            }
        });
        Object.defineProperty(this, "removeAllSearchTags", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: function () {
                _this.searchTags = [];
            }
        });
        Object.defineProperty(this, "loadRecentSearches", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: function () {
                if (_this.recentSearches.length) {
                    return _this.recentSearches;
                }
                var savedRecentSearches = DataStorage.getPersistItem(DataType.RecentSearches);
                _this.recentSearches = savedRecentSearches || [];
                return _this.recentSearches;
            }
        });
        Object.defineProperty(this, "addRecentSearchItem", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: function (searchString, item) {
                // Find matching item or string
                var existingItemIndex = _this.recentSearches.findIndex(function (rs) { return (rs.item.id && rs.item.id === item.id) || rs.searchString === searchString; });
                if (existingItemIndex === -1) {
                    _this.recentSearches.push({
                        item: item,
                        searchString: searchString,
                        dateTime: Date.now(),
                    });
                }
                else {
                    // Already exists on list, just update search timestamp
                    _this.recentSearches[existingItemIndex].dateTime = Date.now();
                }
                DataStorage.setPersistItem(DataType.RecentSearches, _this.recentSearchesList);
            }
        });
        Object.defineProperty(this, "fetchCategories", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: function (forceUpdate) {
                if (forceUpdate === void 0) { forceUpdate = false; }
                return __awaiter(_this, void 0, void 0, function () {
                    var categories, response;
                    return __generator(this, function (_a) {
                        switch (_a.label) {
                            case 0:
                                categories = DataStorage.getPersistItem(DataType.Categories, this.CACHE_TIME);
                                if (!(!forceUpdate && categories)) return [3 /*break*/, 1];
                                this.allCategories = categories;
                                return [3 /*break*/, 3];
                            case 1: return [4 /*yield*/, categoryApi.getAll()];
                            case 2:
                                response = _a.sent();
                                DataStorage.setPersistItem(DataType.Categories, response);
                                this.allCategories = response;
                                _a.label = 3;
                            case 3: return [2 /*return*/, this.allCategories];
                        }
                    });
                });
            }
        });
        Object.defineProperty(this, "fetchSubcategories", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: function (forceUpdate) {
                if (forceUpdate === void 0) { forceUpdate = false; }
                return __awaiter(_this, void 0, void 0, function () {
                    var subcategories, response;
                    return __generator(this, function (_a) {
                        switch (_a.label) {
                            case 0:
                                subcategories = DataStorage.getPersistItem(DataType.Subcategories, this.CACHE_TIME);
                                if (!(!forceUpdate && subcategories)) return [3 /*break*/, 1];
                                this.allSubcategories = subcategories;
                                return [3 /*break*/, 3];
                            case 1: return [4 /*yield*/, subcategoryApi.getAll()];
                            case 2:
                                response = _a.sent();
                                DataStorage.setPersistItem(DataType.Subcategories, response);
                                this.allSubcategories = response;
                                _a.label = 3;
                            case 3: return [2 /*return*/, this.allSubcategories];
                        }
                    });
                });
            }
        });
        Object.defineProperty(this, "fetchSkills", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: function (forceUpdate) {
                if (forceUpdate === void 0) { forceUpdate = false; }
                return __awaiter(_this, void 0, void 0, function () {
                    var skills, response;
                    return __generator(this, function (_a) {
                        switch (_a.label) {
                            case 0:
                                skills = DataStorage.getPersistItem(DataType.Skills, this.CACHE_TIME);
                                if (!(!forceUpdate && skills)) return [3 /*break*/, 1];
                                this.allSkills = skills;
                                return [3 /*break*/, 3];
                            case 1: return [4 /*yield*/, skillApi.getAll()];
                            case 2:
                                response = _a.sent();
                                DataStorage.setPersistItem(DataType.Skills, response);
                                this.allSkills = response;
                                _a.label = 3;
                            case 3: return [2 /*return*/, this.allSkills];
                        }
                    });
                });
            }
        });
        Object.defineProperty(this, "fetchEditableSkillsForUser", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: function (id) { return __awaiter(_this, void 0, void 0, function () {
                var allSkills, existingUserSkills;
                var _this = this;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0: return [4 /*yield*/, this.fetchSkills()];
                        case 1:
                            allSkills = _a.sent();
                            return [4 /*yield*/, userApi.getUserSkills(id)];
                        case 2:
                            existingUserSkills = _a.sent();
                            runInAction(function () {
                                _this.editableUserSkills = allSkills.map(function (s) {
                                    var foundedSkills = existingUserSkills.filter(function (_a) {
                                        var skill = _a.skill;
                                        return skill.id === s.id;
                                    });
                                    var currentScore = foundedSkills[0] ? foundedSkills[0].score : 0;
                                    return {
                                        id: s.id,
                                        name: s.name,
                                        subcategory_id: s.subcategory_id,
                                        score: currentScore,
                                        savedScore: currentScore,
                                    };
                                });
                            });
                            return [2 /*return*/];
                    }
                });
            }); }
        });
        Object.defineProperty(this, "fetchUsers", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: function (forceUpdate) {
                if (forceUpdate === void 0) { forceUpdate = false; }
                return __awaiter(_this, void 0, void 0, function () {
                    var users, response;
                    return __generator(this, function (_a) {
                        switch (_a.label) {
                            case 0:
                                users = DataStorage.getPersistItem(DataType.Users, this.CACHE_TIME);
                                if (!(!forceUpdate && users)) return [3 /*break*/, 1];
                                this.users = users;
                                return [3 /*break*/, 3];
                            case 1: return [4 /*yield*/, searchApi.search()];
                            case 2:
                                response = _a.sent();
                                DataStorage.setPersistItem(DataType.Users, response);
                                this.users = response;
                                _a.label = 3;
                            case 3: return [2 /*return*/, this.users];
                        }
                    });
                });
            }
        });
        Object.defineProperty(this, "removeUserSkill", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: function (userId, skill) {
                return userApi.removeUserSkill(userId, skill.id);
            }
        });
        Object.defineProperty(this, "addUserSkill", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: function (skill) {
                return userApi.addUserSkill(skill.id, skill.score);
            }
        });
        Object.defineProperty(this, "updateUserSkill", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: function (skill) {
                return userApi.updateUserSkill(skill.id, skill.score);
            }
        });
        Object.defineProperty(this, "fetchSkillsForUser", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: function (userId) {
                _this.loading = true;
                userApi.getUserSkillsTree(userId).then(function (skillTree) {
                    _this.userSkills = { categories: skillTree.categories ? skillTree.categories : [] };
                    _this.loading = false;
                });
            }
        });
        Object.defineProperty(this, "fetchData", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: function () {
                var promises = [
                    _this.fetchUsers(),
                    _this.fetchCategories(),
                    _this.fetchSubcategories(),
                ];
                return Promise.all(promises);
            }
        });
        Object.defineProperty(this, "discardChangesToEditableUserSkills", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: function (userId) {
                _this.fetchEditableSkillsForUser(userId);
            }
        });
        makeObservable(this);
    }
    Object.defineProperty(DataStore.prototype, "recentSearchesList", {
        get: function () {
            var sortSearches = function (a, b) {
                return a.dateTime > b.dateTime ? -1 : 1;
            };
            return this.loadRecentSearches()
                .slice()
                .sort(sortSearches)
                .slice(0, 5);
        },
        enumerable: false,
        configurable: true
    });
    __decorate([
        observable,
        __metadata("design:type", Array)
    ], DataStore.prototype, "users", void 0);
    __decorate([
        observable,
        __metadata("design:type", Object)
    ], DataStore.prototype, "userSkills", void 0);
    __decorate([
        observable,
        __metadata("design:type", Array)
    ], DataStore.prototype, "editableUserSkills", void 0);
    __decorate([
        observable,
        __metadata("design:type", Array)
    ], DataStore.prototype, "searchTags", void 0);
    __decorate([
        observable,
        __metadata("design:type", Array)
    ], DataStore.prototype, "allCategories", void 0);
    __decorate([
        observable,
        __metadata("design:type", Array)
    ], DataStore.prototype, "allSubcategories", void 0);
    __decorate([
        observable,
        __metadata("design:type", Array)
    ], DataStore.prototype, "allSkills", void 0);
    __decorate([
        observable,
        __metadata("design:type", Array)
    ], DataStore.prototype, "recentSearches", void 0);
    __decorate([
        observable,
        __metadata("design:type", Object)
    ], DataStore.prototype, "currentSearchItem", void 0);
    __decorate([
        observable,
        __metadata("design:type", Object)
    ], DataStore.prototype, "noResultsWithSearch", void 0);
    __decorate([
        observable,
        __metadata("design:type", Object)
    ], DataStore.prototype, "loading", void 0);
    __decorate([
        observable,
        __metadata("design:type", Array)
    ], DataStore.prototype, "searchResults", void 0);
    __decorate([
        action,
        __metadata("design:type", Object)
    ], DataStore.prototype, "setSearchResults", void 0);
    __decorate([
        action,
        __metadata("design:type", Object)
    ], DataStore.prototype, "executeSearch", void 0);
    __decorate([
        action,
        __metadata("design:type", Object)
    ], DataStore.prototype, "setCurrentSearchItem", void 0);
    __decorate([
        action,
        __metadata("design:type", Object)
    ], DataStore.prototype, "getSuggestions", void 0);
    __decorate([
        action,
        __metadata("design:type", Object)
    ], DataStore.prototype, "setNoResultsWithString", void 0);
    __decorate([
        action,
        __metadata("design:type", Object)
    ], DataStore.prototype, "addSearchTag", void 0);
    __decorate([
        action,
        __metadata("design:type", Object)
    ], DataStore.prototype, "removeSearchTag", void 0);
    __decorate([
        action,
        __metadata("design:type", Object)
    ], DataStore.prototype, "removeAllSearchTags", void 0);
    __decorate([
        action,
        __metadata("design:type", Object)
    ], DataStore.prototype, "loadRecentSearches", void 0);
    __decorate([
        action,
        __metadata("design:type", Object)
    ], DataStore.prototype, "addRecentSearchItem", void 0);
    __decorate([
        computed,
        __metadata("design:type", Array),
        __metadata("design:paramtypes", [])
    ], DataStore.prototype, "recentSearchesList", null);
    __decorate([
        action,
        __metadata("design:type", Object)
    ], DataStore.prototype, "fetchCategories", void 0);
    __decorate([
        action,
        __metadata("design:type", Object)
    ], DataStore.prototype, "fetchSubcategories", void 0);
    __decorate([
        action,
        __metadata("design:type", Object)
    ], DataStore.prototype, "fetchSkills", void 0);
    __decorate([
        action,
        __metadata("design:type", Object)
    ], DataStore.prototype, "fetchEditableSkillsForUser", void 0);
    __decorate([
        action,
        __metadata("design:type", Object)
    ], DataStore.prototype, "fetchUsers", void 0);
    __decorate([
        action,
        __metadata("design:type", Object)
    ], DataStore.prototype, "removeUserSkill", void 0);
    __decorate([
        action,
        __metadata("design:type", Object)
    ], DataStore.prototype, "addUserSkill", void 0);
    __decorate([
        action,
        __metadata("design:type", Object)
    ], DataStore.prototype, "updateUserSkill", void 0);
    __decorate([
        action,
        __metadata("design:type", Object)
    ], DataStore.prototype, "fetchSkillsForUser", void 0);
    __decorate([
        action,
        __metadata("design:type", Object)
    ], DataStore.prototype, "fetchData", void 0);
    __decorate([
        action,
        __metadata("design:type", Object)
    ], DataStore.prototype, "discardChangesToEditableUserSkills", void 0);
    return DataStore;
}());
export var dataStore = new DataStore();
