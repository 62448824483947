import './wdyr';
import React from 'react';
import * as ReactDOMClient from 'react-dom/client';
import { BrowserRouter } from 'react-router-dom';
import analytics from 'app/utils/analytics';
import { registerServiceWorker } from 'utils/registerServiceWorker';
import { ID } from './constants';
import { RootView } from './views';
analytics.initialize();
registerServiceWorker(); // TODO: SW disabled. Fix profile saving issue with Safari.
var container = document.getElementById(ID.ROOT);
var root = ReactDOMClient.createRoot(container);
root.render(React.createElement(BrowserRouter, { basename: "/" },
    React.createElement(RootView, null)));
export default RootView;
