var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
import React, { useRef, useState, useContext, useEffect } from 'react';
import { FaSearch, FaTimes } from 'react-icons/fa';
import styled from 'styled-components';
import { observer } from 'mobx-react-lite';
import { AppContext } from 'store';
import { SearchItemType } from 'app/constants';
import { DropdownContent } from './dropdown-content';
var SearchFieldComponent = function (_a) {
    var value = _a.value, onExecuteSearch = _a.onExecuteSearch, placeholder = _a.placeholder;
    var dataStore = useContext(AppContext).dataStore;
    var inputRef = useRef(null);
    var containerRef = useRef(null);
    var _b = useState(false), dropdownVisible = _b[0], setDropdownVisible = _b[1];
    var _c = useState([]), dropdownItems = _c[0], setDropdownItems = _c[1];
    var _d = useState(''), dropdownTitle = _d[0], setDropdownTitle = _d[1];
    var _e = useState(false), allowClear = _e[0], setAllowClear = _e[1];
    useEffect(function () {
        document.addEventListener('mousedown', onDocumentClick);
        return function () {
            document.removeEventListener('mousedown', onDocumentClick);
        };
    }, []);
    useEffect(function () {
        setCurrentInputValue(value.query);
        setAllowClear(!!value.query.length);
    }, [value]);
    var getCurrentContainerRef = function () {
        return containerRef && containerRef.current;
    };
    var getCurrentInputRef = function () {
        return inputRef && inputRef.current;
    };
    var getCurrentInputValue = function () {
        var ref = getCurrentInputRef();
        return ref ? ref.value : '';
    };
    var setCurrentInputValue = function (inputValue) {
        var ref = getCurrentInputRef();
        if (ref) {
            ref.value = inputValue;
        }
    };
    // Handle dropdown closing when clicking outside of the searchfield / dropdown content
    var onDocumentClick = function (e) {
        var ref = getCurrentContainerRef();
        if (ref && ref.contains(e.target)) {
            return;
        }
        setDropdownVisible(false);
    };
    var onSearchIconClick = function () {
        var ref = getCurrentInputRef();
        ref && ref.focus();
    };
    var onClearSearchField = function () {
        dataStore.removeAllSearchTags();
        setDropdownItems(getRecentSearches());
        setDropdownTitle('Recent searches');
        setAllowClear(false);
    };
    var executeSearch = function (selectedSearchItem) {
        var query = getCurrentInputValue();
        var team = value.team, location = value.location;
        if (query.length) {
            dataStore.addRecentSearchItem(query, selectedSearchItem);
            setAllowClear(true);
        }
        setDropdownVisible(false);
        if (onExecuteSearch) {
            var type = selectedSearchItem.type, id = selectedSearchItem.id;
            onExecuteSearch({ query: query, type: type, id: id, team: team, location: location });
        }
    };
    var getSuggestions = function (query) {
        return dataStore.getSuggestions(query);
    };
    var getRecentSearches = function () {
        var formatDate = function (date) {
            return new Date(date).toLocaleDateString('fi');
        };
        return dataStore.recentSearchesList.map(function (searchItem) { return ({
            id: searchItem.item && searchItem.item.id,
            type: searchItem.item.type,
            title: searchItem.searchString,
            description: formatDate(searchItem.dateTime),
        }); });
    };
    var onSearchInputChange = function () {
        var query = getCurrentInputValue();
        if (query.length) {
            var suggestions = getSuggestions(query);
            if (suggestions.length) {
                setDropdownItems(suggestions);
                setDropdownTitle('');
            }
            else {
                setDropdownTitle("No suggestions for \"".concat(query, "\""));
            }
            setAllowClear(true);
            setDropdownVisible(true);
        }
        else {
            onClearSearchField();
        }
    };
    var onSearchInputFocus = function () {
        setDropdownVisible(true);
        onSearchInputChange();
    };
    var executeEmptySearch = function () {
        var searchItem = {
            type: SearchItemType.Other,
            title: '',
            priority: 0,
        };
        executeSearch(searchItem);
    };
    var onSearchInputKeyDown = function (event) {
        if (event.keyCode === 13) {
            // When there's no person or skill selected, search with string only
            executeEmptySearch();
        }
    };
    var onClearClick = function () {
        setCurrentInputValue('');
        onClearSearchField();
        onSearchInputChange();
        executeEmptySearch();
    };
    var onDropdownItemSelect = function (item) {
        setCurrentInputValue(item.title);
        executeSearch(item);
    };
    return (React.createElement(Container, { ref: containerRef },
        React.createElement(InputWrapper, { dropdownVisible: dropdownVisible },
            React.createElement(SearchIcon, { onClick: onSearchIconClick }),
            value.team && (React.createElement(SearchInputLabel, { onClick: onSearchIconClick }, value.team)),
            value.location && (React.createElement(SearchInputLabel, { onClick: onSearchIconClick }, value.location)),
            React.createElement(SearchInput, { ref: inputRef, placeholder: placeholder, onChange: onSearchInputChange, onFocus: onSearchInputFocus, onKeyDown: onSearchInputKeyDown }),
            allowClear && React.createElement(ClearIcon, { onClick: onClearClick })),
        dropdownVisible && (React.createElement(DropdownContainer, null,
            React.createElement(ShadowHider, null),
            React.createElement(DropdownWrapper, null,
                React.createElement(DropdownContent, { title: dropdownTitle, items: dropdownItems, onItemSelect: onDropdownItemSelect }))))));
};
var Container = styled.div(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  flex: 1;\n  position: relative;\n"], ["\n  flex: 1;\n  position: relative;\n"])));
var InputWrapper = styled.div(function (_a) {
    var dropdownVisible = _a.dropdownVisible, theme = _a.theme;
    return "\n  height: 5rem;\n  display: flex;\n  align-items: center;\n  padding: 0 1rem;\n  border-radius: ".concat(dropdownVisible
        ? "".concat(theme.borderRadius.small, " ").concat(theme.borderRadius.small, " 0 0")
        : theme.borderRadius.small, ";\n  background: ").concat(dropdownVisible ? theme.color.bgCard : theme.color.bg2, ";\n  box-shadow: ").concat(dropdownVisible ? '0 1px 6px 0 rgba(32,33,36,0.28)' : 'none', ";\n  color: ").concat(theme.color.txt2, ";\n");
});
var SearchIcon = styled(FaSearch)(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n  padding: 0;\n  font-size: 2rem;\n  color: ", ";\n  margin: 0 1rem 0 0.6rem;\n"], ["\n  padding: 0;\n  font-size: 2rem;\n  color: ", ";\n  margin: 0 1rem 0 0.6rem;\n"])), function (_a) {
    var theme = _a.theme;
    return theme.color.txt1;
});
var ClearIcon = styled(FaTimes)(templateObject_3 || (templateObject_3 = __makeTemplateObject(["\n  padding: 0;\n  color: ", ";\n  margin: 0 0.5rem 0 0.2rem;\n  font-size: 2rem;\n  cursor: pointer;\n"], ["\n  padding: 0;\n  color: ", ";\n  margin: 0 0.5rem 0 0.2rem;\n  font-size: 2rem;\n  cursor: pointer;\n"])), function (_a) {
    var theme = _a.theme;
    return theme.color.txt1;
});
var SearchInputLabel = styled.div(templateObject_4 || (templateObject_4 = __makeTemplateObject(["\n  font-family: ", ";\n  font-size: 1rem;\n  font-weight: 700;\n  cursor: default;\n  letter-spacing: 0.2rem;\n  padding: 0.6rem 0.8rem;\n  background-color: ", ";\n  color: ", ";\n  border: none;\n  margin: 0.3rem;\n  text-transform: uppercase;\n  border-radius: 5px;\n"], ["\n  font-family: ", ";\n  font-size: 1rem;\n  font-weight: 700;\n  cursor: default;\n  letter-spacing: 0.2rem;\n  padding: 0.6rem 0.8rem;\n  background-color: ", ";\n  color: ", ";\n  border: none;\n  margin: 0.3rem;\n  text-transform: uppercase;\n  border-radius: 5px;\n"])), function (_a) {
    var theme = _a.theme;
    return theme.font.secondary;
}, function (_a) {
    var theme = _a.theme;
    return theme.color.txt1;
}, function (_a) {
    var theme = _a.theme;
    return theme.color.txtInverted;
});
var SearchInput = styled.input(templateObject_5 || (templateObject_5 = __makeTemplateObject(["\n  border: 0;\n  background: transparent;\n  margin-left: 0.3rem;\n  flex: 1 1 auto;\n  font-size: 1.6rem;\n  padding: 1rem 0;\n  color: inherit;\n  outline: none;\n"], ["\n  border: 0;\n  background: transparent;\n  margin-left: 0.3rem;\n  flex: 1 1 auto;\n  font-size: 1.6rem;\n  padding: 1rem 0;\n  color: inherit;\n  outline: none;\n"])));
var DropdownContainer = styled.div(templateObject_6 || (templateObject_6 = __makeTemplateObject(["\n  width: 100%;\n  position: absolute;\n  z-index: 200;\n  width: 100%;\n  background: ", ";\n  border-radius: 0 0 0.4rem 0.4rem;\n  box-shadow: 0 1px 6px 0 rgba(32, 33, 36, 0.28);\n  border: 0;\n"], ["\n  width: 100%;\n  position: absolute;\n  z-index: 200;\n  width: 100%;\n  background: ", ";\n  border-radius: 0 0 0.4rem 0.4rem;\n  box-shadow: 0 1px 6px 0 rgba(32, 33, 36, 0.28);\n  border: 0;\n"])), function (_a) {
    var theme = _a.theme;
    return theme.color.bgCard;
});
var DropdownWrapper = styled.div(templateObject_7 || (templateObject_7 = __makeTemplateObject(["\n  padding: 1rem 5rem;\n"], ["\n  padding: 1rem 5rem;\n"])));
var ShadowHider = styled.div(templateObject_8 || (templateObject_8 = __makeTemplateObject(["\n  position: absolute;\n  width: 100%;\n  background: inherit;\n  top: -5px;\n  height: 5px;\n"], ["\n  position: absolute;\n  width: 100%;\n  background: inherit;\n  top: -5px;\n  height: 5px;\n"])));
export var SearchField = observer(SearchFieldComponent);
var templateObject_1, templateObject_2, templateObject_3, templateObject_4, templateObject_5, templateObject_6, templateObject_7, templateObject_8;
