var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import React, { useCallback, useContext, useEffect, useState } from 'react';
import { observer } from 'mobx-react-lite';
import styled from 'styled-components';
import { FaDoorOpen, FaEdit, FaGlassCheers, FaGrinBeamSweat } from 'react-icons/fa';
import { AppContext } from 'store';
import { useNavigate } from 'react-router-dom';
import { FlexContainer, FlexItem, ProfileImage, Loader } from 'app/components';
import ReactTooltip from 'react-tooltip';
// Need to import some components separately
// or styled-components will complain about circular import error
import { useAuth } from 'app/hooks';
import { SearchItemType, Theme } from 'app/constants';
import analytics, { GTMEventGategory, GTMEventType } from 'app/utils/analytics';
import { useOnlineStatus } from 'hooks/use-online-status';
import { isValidURL } from 'utils/helpers';
import { IconButton } from '../icon-button';
import { Subcategory } from './subcategory';
import { Category } from './category';
import { ThemeSwitch } from './theme-switch';
import { EditProfileDetails } from './edit-profile-details';
import { ProfileDetails } from './profile-details';
import { SkillList } from './skill-list';
var ProfileComponent = function (_a) {
    var user = _a.user;
    var navigate = useNavigate();
    var _b = useState(false), allowEditing = _b[0], setAllowEditing = _b[1];
    var _c = useState(true), isLoading = _c[0], setLoading = _c[1];
    var _d = useContext(AppContext), dataStore = _d.dataStore, userStore = _d.userStore, portalStore = _d.portalStore;
    var logout = useAuth().logout;
    var allCategories = dataStore.allCategories, allSubcategories = dataStore.allSubcategories, editableUserSkills = dataStore.editableUserSkills;
    var editMode = portalStore.editMode;
    var isOnline = useOnlineStatus().isOnline;
    useEffect(function () {
        if (user) {
            if (userStore.user && user.id === userStore.user.id) {
                setAllowEditing(true);
                analytics.triggerEvent(GTMEventType.ProfileViewOwn, {
                    category: GTMEventGategory.Profile,
                });
            }
            else {
                setAllowEditing(false);
                analytics.triggerEvent(GTMEventType.ProfileView, {
                    category: GTMEventGategory.Profile,
                    label: user.name,
                });
            }
            Promise.all([
                dataStore.fetchCategories(),
                dataStore.fetchSubcategories(),
                dataStore.fetchEditableSkillsForUser(user.id),
            ]).then(function () {
                /**
                 * This will rebind tooltips.
                 */
                ReactTooltip.rebuild();
                setLoading(false);
            });
        }
    }, [user, editMode]);
    var onEditClick = function () {
        if (!isOnline) {
            alert('You cannot edit your profile details until network connection is restored.'); // eslint-disable-line no-alert
            return;
        }
        portalStore.setEditMode(true);
        analytics.triggerEvent(GTMEventType.ProfileEdit, {
            category: GTMEventGategory.Profile,
        });
    };
    var validateProfileFields = function () {
        var cv = userStore.profileFields.cv;
        if (cv && isValidURL(cv) === false) {
            // eslint-disable-next-line no-alert
            alert('CV url must be valid, secured url.');
            return false;
        }
        return true;
    };
    var saveChanges = function () {
        if (!userStore.user)
            return;
        if (validateProfileFields() === false) {
            return;
        }
        portalStore.setEditMode(false);
        analytics.triggerEvent(GTMEventType.ProfileSave, {
            category: GTMEventGategory.Profile,
        });
        /**
         * Map skills data before updating
         */
        var profileData = __assign(__assign({}, userStore.profileFields), { skills: dataStore.editableUserSkills
                .filter(function (skill) { return skill.score > 0; })
                .map(function (skill) { return ({ id: skill.id, score: skill.score }); }) });
        setLoading(true);
        userStore
            .updateUserProfile(profileData)
            .then(function () {
            return Promise.all([
                dataStore.fetchEditableSkillsForUser(user.id),
                dataStore.fetchUsers(true),
            ]);
        })
            .finally(function () {
            setLoading(false);
        });
    };
    var discardChanges = function () {
        if (!userStore.user)
            return;
        portalStore.setEditMode(false);
        analytics.triggerEvent(GTMEventType.ProfileCancelEdit, {
            category: GTMEventGategory.Profile,
        });
        dataStore.discardChangesToEditableUserSkills(userStore.user.id);
    };
    var signOut = function () {
        if (allowEditing) {
            logout();
        }
    };
    var onSkillChange = useCallback(function (skillId, skillLevel) {
        Object.keys(editableUserSkills).forEach(function (sIdx) {
            if (editableUserSkills[sIdx].id === skillId) {
                editableUserSkills[sIdx].score = skillLevel;
            }
        });
    }, [editableUserSkills]);
    var onSkillClick = useCallback(function (skill) {
        portalStore.setEditMode(false);
        navigate("/?search=".concat(skill.name, "&type=").concat(SearchItemType.Skill, "&id=").concat(skill.id));
    }, []);
    var getSkillsBySubcategory = function (subcategoryId) {
        return editableUserSkills.filter(function (item) { return item.subcategory_id === subcategoryId; });
    };
    var generateSkillElems = function (subcategoryId) {
        // Sort the skill based on score if not in edit mode
        var skills = editMode
            ? getSkillsBySubcategory(subcategoryId)
            : getSkillsBySubcategory(subcategoryId)
                .filter(function (skill) { return skill.score > 0; })
                .sort(function (a, b) {
                return b.score && a.score ? b.score - a.score : 0;
            });
        return (React.createElement(SkillList, { skills: skills, isEditMode: editMode, onChange: onSkillChange, onSkillClick: onSkillClick }));
    };
    var getSkillCountFromSubcategory = function (subcategoryId) {
        return editableUserSkills.filter(function (skill) { return skill.subcategory_id === subcategoryId && skill.score > 0; }).length;
    };
    var getSubcategories = function (categoryId) {
        return allSubcategories.filter(function (sc) { return sc.category_id === categoryId; });
    };
    var generateSubcategoryElems = function (categoryId) {
        var subcategoryElems = [];
        getSubcategories(categoryId).forEach(function (_a) {
            var id = _a.id, name = _a.name;
            var skillsCount = getSkillCountFromSubcategory(id);
            var expanded = !editMode || skillsCount > 0;
            if (!editMode && skillsCount === 0)
                return;
            subcategoryElems.push(React.createElement(Subcategory, { id: id, key: "subcat".concat(id), name: name, expanded: expanded, vertical: editMode }, generateSkillElems(id)));
        });
        return subcategoryElems;
    };
    var getSkillCountFromCategory = function (categoryId) {
        var subCategories = getSubcategories(categoryId);
        var skillCount = 0;
        subCategories.forEach(function (subcategory) {
            skillCount += getSkillCountFromSubcategory(subcategory.id);
        });
        return skillCount;
    };
    var generateCategoryElems = function () {
        var categoryElems = [];
        allCategories.forEach(function (_a) {
            var id = _a.id, name = _a.name;
            var skillsCount = getSkillCountFromCategory(id);
            if (!editMode && skillsCount === 0)
                return;
            categoryElems.push(React.createElement(Category, { key: "cat".concat(id), name: name }, generateSubcategoryElems(id)));
        });
        return categoryElems;
    };
    var generateActionButtons = function () {
        if (editMode) {
            return (React.createElement(React.Fragment, null,
                React.createElement(CancelButton, { title: "Cancel", Icon: FaGrinBeamSweat, onClick: discardChanges }),
                React.createElement(SaveButton, { title: "Save changes", Icon: FaGlassCheers, onClick: saveChanges })));
        }
        return React.createElement(EditButton, { title: "Edit profile", Icon: FaEdit, onClick: onEditClick });
    };
    var onChangeTheme = function (isOn) {
        var themeName = isOn ? Theme.Light : Theme.Dark;
        portalStore.setTheme(themeName);
        analytics.triggerEvent(GTMEventType.ThemeChange, {
            category: GTMEventGategory.Profile,
            label: themeName,
        });
    };
    if (isLoading) {
        return (React.createElement(LoaderContainer, null,
            React.createElement(Loader, { title: "Loading user profile..." })));
    }
    return (React.createElement(Container, { fluid: true },
        React.createElement(FlexItem, { lg: 4 },
            React.createElement(UserImageColumn, null,
                React.createElement(ProfileImage, { user: user, size: 22 })),
            allowEditing && (React.createElement(ProfileActions, null,
                React.createElement(ThemeSwitcher, { isOn: portalStore.theme === Theme.Light, onChange: onChangeTheme }),
                React.createElement(SignOutButton, { title: "Sign out", onClick: signOut, Icon: FaDoorOpen })))),
        React.createElement(FlexItem, { lg: 8 },
            React.createElement(UserInfoColumn, null,
                editMode && React.createElement(EditProfileDetails, { user: user }),
                !editMode && React.createElement(ProfileDetails, { user: user }),
                React.createElement(SkillContainer, null, generateCategoryElems()),
                allowEditing && React.createElement(ButtonContainer, null, generateActionButtons())))));
};
var Container = styled(FlexContainer)(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  margin-top: 5rem;\n  margin-bottom: 3rem;\n  ", "\n"], ["\n  margin-top: 5rem;\n  margin-bottom: 3rem;\n  ", "\n"])), function (_a) {
    var theme = _a.theme;
    return theme.onMobile("\n      padding: 0 1.5rem;\n    ");
});
var UserImageColumn = styled.div(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n  display: flex;\n  flex: 0 0 30rem;\n  justify-content: center;\n  margin-bottom: 2rem;\n"], ["\n  display: flex;\n  flex: 0 0 30rem;\n  justify-content: center;\n  margin-bottom: 2rem;\n"])));
var UserInfoColumn = styled.div(templateObject_3 || (templateObject_3 = __makeTemplateObject(["\n  flex: 1;\n"], ["\n  flex: 1;\n"])));
var SkillContainer = styled.div(templateObject_4 || (templateObject_4 = __makeTemplateObject(["\n  overflow: hidden;\n"], ["\n  overflow: hidden;\n"])));
var ButtonContainer = styled.div(templateObject_5 || (templateObject_5 = __makeTemplateObject(["\n  position: fixed;\n  z-index: 100;\n  bottom: 2rem;\n  right: 2rem;\n  display: flex;\n  justify-content: space-between;\n"], ["\n  position: fixed;\n  z-index: 100;\n  bottom: 2rem;\n  right: 2rem;\n  display: flex;\n  justify-content: space-between;\n"])));
var ActionButton = styled(IconButton)(templateObject_6 || (templateObject_6 = __makeTemplateObject(["\n  margin: 0.5rem;\n  box-shadow: 0 0 8px rgba(0, 0, 0, 0.4);\n"], ["\n  margin: 0.5rem;\n  box-shadow: 0 0 8px rgba(0, 0, 0, 0.4);\n"])));
var CancelButton = styled(ActionButton)(templateObject_7 || (templateObject_7 = __makeTemplateObject(["\n  background: #ffc106;\n"], ["\n  background: #ffc106;\n"])));
var SaveButton = styled(ActionButton)(templateObject_8 || (templateObject_8 = __makeTemplateObject([""], [""])));
var EditButton = styled(ActionButton)(templateObject_9 || (templateObject_9 = __makeTemplateObject([""], [""])));
var ThemeSwitcher = styled(ThemeSwitch)(templateObject_10 || (templateObject_10 = __makeTemplateObject(["\n  margin: 2rem auto;\n"], ["\n  margin: 2rem auto;\n"])));
var ProfileActions = styled.div(templateObject_11 || (templateObject_11 = __makeTemplateObject(["\n  display: flex;\n  flex-direction: column;\n  align-items: center;\n  margin-bottom: 3rem;\n"], ["\n  display: flex;\n  flex-direction: column;\n  align-items: center;\n  margin-bottom: 3rem;\n"])));
var SignOutButton = styled(IconButton)(templateObject_12 || (templateObject_12 = __makeTemplateObject(["\n  font-size: 1.2rem;\n  padding: 0.6rem 1.2rem;\n  border-radius: 0.5rem;\n"], ["\n  font-size: 1.2rem;\n  padding: 0.6rem 1.2rem;\n  border-radius: 0.5rem;\n"])));
var LoaderContainer = styled.div(templateObject_13 || (templateObject_13 = __makeTemplateObject(["\n  display: flex;\n  width: 100%;\n  justify-content: center;\n"], ["\n  display: flex;\n  width: 100%;\n  justify-content: center;\n"])));
export var Profile = observer(ProfileComponent);
var templateObject_1, templateObject_2, templateObject_3, templateObject_4, templateObject_5, templateObject_6, templateObject_7, templateObject_8, templateObject_9, templateObject_10, templateObject_11, templateObject_12, templateObject_13;
