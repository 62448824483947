var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import React from 'react';
import styled from 'styled-components';
export var IconButton = function (_a) {
    var title = _a.title, onClick = _a.onClick, Icon = _a.Icon, className = _a.className, iconStyle = _a.iconStyle;
    return (React.createElement(ButtonWrapper, { onClick: onClick, className: className },
        title,
        React.createElement(Icon, { style: __assign({ marginLeft: '1rem' }, iconStyle) })));
};
var ButtonWrapper = styled.button(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  display: inline-flex;\n  align-items: center;\n  background: ", ";\n  color: ", ";\n  font-weight: 700;\n  padding: 1.3rem 2.3rem;\n  font-size: 1.6rem;\n  font-family: ", ";\n  border: 0;\n  border-radius: ", ";\n  cursor: pointer;\n"], ["\n  display: inline-flex;\n  align-items: center;\n  background: ", ";\n  color: ", ";\n  font-weight: 700;\n  padding: 1.3rem 2.3rem;\n  font-size: 1.6rem;\n  font-family: ", ";\n  border: 0;\n  border-radius: ", ";\n  cursor: pointer;\n"])), function (_a) {
    var theme = _a.theme;
    return theme.color.txt1;
}, function (_a) {
    var theme = _a.theme;
    return theme.color.bg1;
}, function (_a) {
    var theme = _a.theme;
    return theme.font.primary;
}, function (_a) {
    var theme = _a.theme;
    return theme.borderRadius.medium;
});
var templateObject_1;
