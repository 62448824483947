var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { initializeApp } from 'firebase/app';
import { GoogleAuthProvider, getAuth } from 'firebase/auth';
import * as firebaseui from 'firebaseui';
import '../../node_modules/firebaseui/dist/firebaseui.css';
// destructuring does not work with process.env :<
var FIREBASE_API_KEY = process.env.FIREBASE_API_KEY;
var FIREBASE_PROJECT_ID = process.env.FIREBASE_PROJECT_ID;
var FIREBASE_MESSAGING_SENDER_ID = process.env.FIREBASE_MESSAGING_SENDER_ID;
var FIREBASE_APP_ID = process.env.FIREBASE_APP_ID;
if (process.env.NODE_ENV !== 'test' &&
    !(FIREBASE_API_KEY &&
        FIREBASE_PROJECT_ID &&
        FIREBASE_MESSAGING_SENDER_ID &&
        FIREBASE_APP_ID)) {
    console.error('Firebase config missing from .env! Login probably wont work.'); // eslint-disable-line no-console
}
var firebaseConfig = {
    apiKey: FIREBASE_API_KEY,
    authDomain: "".concat(FIREBASE_PROJECT_ID, ".firebaseapp.com"),
    databaseURL: "https://".concat(FIREBASE_PROJECT_ID, ".firebaseio.com"),
    projectId: FIREBASE_PROJECT_ID,
    storageBucket: "https://".concat(FIREBASE_PROJECT_ID, ".appspot.com"),
    messagingSenderId: FIREBASE_MESSAGING_SENDER_ID,
    appId: FIREBASE_APP_ID,
};
var initFirebase = initializeApp(firebaseConfig);
export var firebaseAuth = getAuth(initFirebase);
export var signInOptions = {
    signInSuccessUrl: '/login',
    signInOptions: [
        {
            provider: GoogleAuthProvider.PROVIDER_ID,
            customParameters: {
                // Forces account selection even when one account
                // is available.
                hd: 'qvik.fi',
            },
        },
    ],
    signInFlow: 'popup',
};
export var bindUi = function (el, options) {
    if (options === void 0) { options = signInOptions; }
    var ui = firebaseui.auth.AuthUI.getInstance() || new firebaseui.auth.AuthUI(firebaseAuth);
    ui.start(el, __assign(__assign({}, signInOptions), options));
};
