import React from 'react';
import { useLocation } from 'react-router-dom';
import { observer } from 'mobx-react-lite';
import { FlexContainer, Profile } from 'app/components';
var PersonProfile = function () {
    var location = useLocation();
    var locationState = location.state;
    var user = locationState.user;
    return (React.createElement(FlexContainer, null,
        React.createElement(Profile, { user: user })));
};
export var PersonProfileView = observer(PersonProfile);
