var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
import React from 'react';
import styled from 'styled-components';
import { FaLightbulb, FaRegLightbulb } from 'react-icons/fa';
export var ThemeSwitch = function (_a) {
    var isOn = _a.isOn, className = _a.className, onChange = _a.onChange;
    return (React.createElement(Container, { className: className, onClick: function () { return onChange(!isOn); } }, isOn ? React.createElement(FaLightbulb, { size: "30px" }) : React.createElement(FaRegLightbulb, { size: "30px" })));
};
var Container = styled.button(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  cursor: pointer;\n  display: flex;\n  align-items: center;\n  justify-content: center;\n  border: 0;\n  background: transparent;\n  color: ", ";\n"], ["\n  cursor: pointer;\n  display: flex;\n  align-items: center;\n  justify-content: center;\n  border: 0;\n  background: transparent;\n  color: ", ";\n"])), function (_a) {
    var theme = _a.theme;
    return theme.color.txt1;
});
var templateObject_1;
