var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
import React from 'react';
import styled from 'styled-components';
import { AiOutlineWarning } from 'react-icons/ai';
import { useOnlineStatus } from 'hooks/use-online-status';
/**
 * Provides a popup notification if browser enters offline mode.
 *
 * @returns {React.ReactElement} The notification component
 */
export var OfflineIndicator = function (_a) {
    var _b = _a.forceOffline, forceOffline = _b === void 0 ? false : _b;
    var isOnline = useOnlineStatus().isOnline;
    return (React.createElement(Container, { open: !isOnline || forceOffline },
        React.createElement(Img, null,
            React.createElement(AiOutlineWarning, { size: "5rem", color: "#000" })),
        React.createElement(TextContainer, null, "You are currently offline.")));
};
var Container = styled.div(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  position: fixed;\n  display: flex;\n  justify-content: center;\n  align-items: center;\n  overflow: hidden;\n  z-index: 150;\n  bottom: 0;\n  left: 0;\n  width: 100%;\n  padding: ", ";\n  height: ", ";\n  background-color: ", ";\n  transition: height 0.5s ease-out, padding 0.5s ease-out;\n"], ["\n  position: fixed;\n  display: flex;\n  justify-content: center;\n  align-items: center;\n  overflow: hidden;\n  z-index: 150;\n  bottom: 0;\n  left: 0;\n  width: 100%;\n  padding: ", ";\n  height: ", ";\n  background-color: ", ";\n  transition: height 0.5s ease-out, padding 0.5s ease-out;\n"])), function (_a) {
    var open = _a.open;
    return (open ? '1rem' : '0');
}, function (_a) {
    var open = _a.open;
    return (open ? '8rem' : '0');
}, function (_a) {
    var theme = _a.theme;
    return theme.color.warning;
});
var TextContainer = styled.div(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n  font-family: ", ";\n  color: #000;\n  font-size: 2rem;\n  font-weight: bold;\n"], ["\n  font-family: ", ";\n  color: #000;\n  font-size: 2rem;\n  font-weight: bold;\n"])), function (_a) {
    var theme = _a.theme;
    return theme.font.primary;
});
var Img = styled.div(templateObject_3 || (templateObject_3 = __makeTemplateObject(["\n  flex: 0 0 6rem;\n  margin: 0 1rem;\n"], ["\n  flex: 0 0 6rem;\n  margin: 0 1rem;\n"])));
var templateObject_1, templateObject_2, templateObject_3;
