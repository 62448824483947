import React, { useContext, useState, useEffect } from 'react';
import { observer } from 'mobx-react-lite';
import { AppContext } from 'store';
import { Profile, Loader, FlexContainer } from 'app/components';
var MyProfile = function () {
    var userStore = useContext(AppContext).userStore;
    var _a = useState(true), isLoading = _a[0], setLoading = _a[1];
    var user = userStore.user;
    useEffect(function () {
        userStore.fetchUser().finally(function () {
            setLoading(false);
        });
    }, []);
    if (isLoading) {
        return React.createElement(Loader, { title: "Loading profile.." });
    }
    if (!user) {
        return null;
    }
    return (React.createElement(FlexContainer, null,
        React.createElement(Profile, { user: user })));
};
export var MyProfileView = observer(MyProfile);
