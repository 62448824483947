var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import React, { useContext, useEffect } from 'react';
import styled, { withTheme } from 'styled-components';
import AceEditor from 'react-ace';
import 'ace-builds/src-noconflict/mode-markdown';
import 'ace-builds/src-noconflict/theme-twilight';
import 'ace-builds/src-noconflict/theme-github';
import { FaEnvelope, FaAngleDown } from 'react-icons/fa';
import { UserRole } from 'app/constants';
import { observer } from 'mobx-react-lite';
import { AppContext } from 'store';
var EditProfileDetailsComponent = function (_a) {
    var user = _a.user, theme = _a.theme;
    var userStore = useContext(AppContext).userStore;
    var profileFields = userStore.profileFields, setProfileFields = userStore.setProfileFields;
    useEffect(function () {
        setProfileFields({
            description: user.description,
            phone: user.phone_number,
            role: user.role,
            title: user.title,
            cv: user.cv,
        });
    }, []);
    var markdownCheatsheetUrl = 'https://github.com/adam-p/markdown-here/wiki/Markdown-Cheatsheet';
    var renderRoleOptions = function () {
        return Object.keys(UserRole).map(function (roleValue) { return (React.createElement("option", { key: UserRole[roleValue], value: UserRole[roleValue] }, roleValue.toString())); });
    };
    var onChange = function (event) {
        var _a;
        setProfileFields(__assign(__assign({}, profileFields), (_a = {}, _a[event.currentTarget.name] = event.currentTarget.value, _a)));
    };
    var onDescriptionChange = function (value) {
        setProfileFields(__assign(__assign({}, profileFields), { description: value }));
    };
    return (React.createElement(React.Fragment, null,
        React.createElement(UserName, null, user.name),
        React.createElement(UserEmail, null,
            React.createElement(FaEnvelope, null),
            React.createElement(ContactValue, null, user.email)),
        React.createElement(FieldWrapper, null,
            React.createElement(FieldLabel, { htmlFor: "title" }, "Title"),
            React.createElement(TextInput, { name: "title", type: "text", value: profileFields.title, onChange: onChange })),
        React.createElement(FieldWrapper, null,
            React.createElement(FieldLabel, { htmlFor: "title" }, "Drive link to your CV"),
            React.createElement(TextInput, { name: "cv", type: "text", placeholder: "Paste link here...", value: profileFields.cv, onChange: onChange })),
        React.createElement(FieldWrapper, null,
            React.createElement(FieldLabel, { htmlFor: "role" }, "Role"),
            React.createElement(UserRoleSelect, { name: "role", value: profileFields.role, onChange: onChange }, renderRoleOptions()),
            React.createElement(SelectIcon, null)),
        React.createElement(FieldWrapper, null,
            React.createElement(FieldLabel, { htmlFor: "phone" }, "Phone number"),
            React.createElement(TextInput, { name: "phone", type: "text", value: profileFields.phone, onChange: onChange })),
        React.createElement(FieldWrapper, null,
            React.createElement(FieldLabel, { htmlFor: "description" }, "Description"),
            React.createElement(DescriptionInfo, null,
                "You can use",
                ' ',
                React.createElement("a", { href: markdownCheatsheetUrl, rel: "noopener noreferrer", target: "_blank" }, "Markdown"),
                ' ',
                "-syntax in this field"),
            React.createElement(AceEditor, { mode: "markdown", theme: theme.editorTheme, width: "100%", onChange: onDescriptionChange, name: "description", value: profileFields.description }))));
};
var UserName = styled.h1(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  font-size: 5rem;\n  font-weight: 400;\n  font-family: ", ";\n  margin: 0;\n  line-height: 1em;\n"], ["\n  font-size: 5rem;\n  font-weight: 400;\n  font-family: ", ";\n  margin: 0;\n  line-height: 1em;\n"])), function (_a) {
    var theme = _a.theme;
    return theme.font.primary;
});
var FieldWrapper = styled.div(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n  margin-bottom: 1.5rem;\n"], ["\n  margin-bottom: 1.5rem;\n"])));
var TextInput = styled.input(templateObject_3 || (templateObject_3 = __makeTemplateObject(["\n  display: block;\n  font-family: ", ";\n  border: 0;\n  background: ", ";\n  font-size: 2rem;\n  padding: 0.8rem;\n  color: ", ";\n  border-radius: 0.3rem;\n  width: 60%;\n"], ["\n  display: block;\n  font-family: ", ";\n  border: 0;\n  background: ", ";\n  font-size: 2rem;\n  padding: 0.8rem;\n  color: ", ";\n  border-radius: 0.3rem;\n  width: 60%;\n"])), function (_a) {
    var theme = _a.theme;
    return theme.font.primary;
}, function (_a) {
    var theme = _a.theme;
    return theme.color.inputBg;
}, function (_a) {
    var theme = _a.theme;
    return theme.color.inputTxt;
});
var FieldLabel = styled.label(templateObject_4 || (templateObject_4 = __makeTemplateObject(["\n  font-family: ", ";\n  display: block;\n  border: 0;\n  color: ", ";\n  font-size: 1.8rem;\n  margin: 1rem 0 0.6rem 0;\n"], ["\n  font-family: ", ";\n  display: block;\n  border: 0;\n  color: ", ";\n  font-size: 1.8rem;\n  margin: 1rem 0 0.6rem 0;\n"])), function (_a) {
    var theme = _a.theme;
    return theme.font.primary;
}, function (_a) {
    var theme = _a.theme;
    return theme.color.txt1;
});
var UserEmail = styled.div(templateObject_5 || (templateObject_5 = __makeTemplateObject(["\n  display: inline-flex;\n  align-items: center;\n  margin: 0.6rem 0;\n  font-family: ", ";\n  font-weight: 500;\n  font-size: 2rem;\n"], ["\n  display: inline-flex;\n  align-items: center;\n  margin: 0.6rem 0;\n  font-family: ", ";\n  font-weight: 500;\n  font-size: 2rem;\n"])), function (_a) {
    var theme = _a.theme;
    return theme.font.primary;
});
var UserRoleSelect = styled.select(templateObject_6 || (templateObject_6 = __makeTemplateObject(["\n  font-family: ", ";\n  border: 0;\n  background: ", ";\n  font-size: 2rem;\n  padding: 0.8rem;\n  color: ", ";\n  border-radius: 0.3rem;\n  width: 60%;\n  appearance: none;\n"], ["\n  font-family: ", ";\n  border: 0;\n  background: ", ";\n  font-size: 2rem;\n  padding: 0.8rem;\n  color: ", ";\n  border-radius: 0.3rem;\n  width: 60%;\n  appearance: none;\n"])), function (_a) {
    var theme = _a.theme;
    return theme.font.primary;
}, function (_a) {
    var theme = _a.theme;
    return theme.color.inputBg;
}, function (_a) {
    var theme = _a.theme;
    return theme.color.inputTxt;
});
var SelectIcon = styled(FaAngleDown)(templateObject_7 || (templateObject_7 = __makeTemplateObject(["\n  position: relative;\n  font-size: 2rem;\n  z-index: 10;\n  right: 3rem;\n  top: 0.4rem;\n  color: ", ";\n  pointer-events: none;\n"], ["\n  position: relative;\n  font-size: 2rem;\n  z-index: 10;\n  right: 3rem;\n  top: 0.4rem;\n  color: ", ";\n  pointer-events: none;\n"])), function (_a) {
    var theme = _a.theme;
    return theme.color.txt3;
});
var DescriptionInfo = styled.p(templateObject_8 || (templateObject_8 = __makeTemplateObject(["\n  color: ", ";\n  font-family: ", ";\n  font-size: 1.4rem;\n\n  a {\n    color: ", ";\n  }\n"], ["\n  color: ", ";\n  font-family: ", ";\n  font-size: 1.4rem;\n\n  a {\n    color: ", ";\n  }\n"])), function (_a) {
    var theme = _a.theme;
    return theme.color.txt3;
}, function (_a) {
    var theme = _a.theme;
    return theme.font.primary;
}, function (_a) {
    var theme = _a.theme;
    return theme.color.txt1;
});
var ContactValue = styled.div(templateObject_9 || (templateObject_9 = __makeTemplateObject(["\n  margin-left: 1rem;\n"], ["\n  margin-left: 1rem;\n"])));
export var EditProfileDetails = withTheme(observer(EditProfileDetailsComponent));
var templateObject_1, templateObject_2, templateObject_3, templateObject_4, templateObject_5, templateObject_6, templateObject_7, templateObject_8, templateObject_9;
