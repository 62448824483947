var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
import React from 'react';
import styled from 'styled-components';
import { CircleIcon, CircleFillIcon } from 'components/icons';
var SkillLevelComponent = function (_a) {
    var _b = _a.fillColor, fillColor = _b === void 0 ? 'white' : _b, skillLevel = _a.skillLevel, _c = _a.size, size = _c === void 0 ? '2em' : _c, title = _a.title;
    var getSkillLevel = function () {
        var iconStyle = { height: size };
        switch (skillLevel) {
            case 1:
                return React.createElement(CircleIcon, { fill: fillColor, style: iconStyle });
            case 3:
                return React.createElement(CircleFillIcon, { fill: fillColor, style: iconStyle });
            default:
                return null;
        }
    };
    return (React.createElement(Container, { title: title, size: size }, getSkillLevel()));
};
var Container = styled.span(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  height: ", ";\n"], ["\n  height: ", ";\n"])), function (_a) {
    var size = _a.size;
    return size;
});
export var SkillLevel = SkillLevelComponent;
var templateObject_1;
