var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
import React from 'react';
import styled from 'styled-components';
import { TeamFilter } from './team-filter';
import { LocationFilter } from './location-filter';
export var Filter = function () {
    return (React.createElement(Container, null,
        React.createElement(TeamFilter, null),
        React.createElement(LocationFilter, null)));
};
var Container = styled.div(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  padding: 3rem 1.3rem;\n"], ["\n  padding: 3rem 1.3rem;\n"])));
var templateObject_1;
