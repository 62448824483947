export var SearchResultMatchType;
(function (SearchResultMatchType) {
    SearchResultMatchType[SearchResultMatchType["UserName"] = 0] = "UserName";
    SearchResultMatchType[SearchResultMatchType["UserTitle"] = 1] = "UserTitle";
    SearchResultMatchType[SearchResultMatchType["UserPhone"] = 2] = "UserPhone";
    SearchResultMatchType[SearchResultMatchType["Subcategory"] = 3] = "Subcategory";
    SearchResultMatchType[SearchResultMatchType["Skill"] = 4] = "Skill";
})(SearchResultMatchType || (SearchResultMatchType = {}));
export var AppError = {
    unknownError: {
        title: 'Unknown error occured',
        code: 600,
    },
    apiError: {
        title: 'API error',
        code: 601,
    },
};
