var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import React, { useContext, useEffect, useState } from 'react';
import styled from 'styled-components';
import { observer } from 'mobx-react-lite';
import { AppContext } from 'store';
import { mapSubcategoryToColor } from 'app/theme';
import { FlexContainer } from 'app/components';
import { SkillList } from 'components/profile/skill-list';
import { Filter } from 'app/components/filter';
var SearchResultEmptyComponent = function (_a) {
    var onSkillClick = _a.onSkillClick, query = _a.query;
    var dataStore = useContext(AppContext).dataStore;
    var _b = useState([]), skillTree = _b[0], setSkillTree = _b[1];
    useEffect(function () {
        Promise.all([dataStore.fetchSkills(), dataStore.fetchSubcategories()]).then(function (res) {
            var skills = res[0], subcategories = res[1];
            buildSkillTree(skills, subcategories);
        });
    }, []);
    var buildSkillTree = function (skills, subcategories) {
        var tree = [];
        subcategories.forEach(function (subcategory) {
            var subcategorySkills = skills.filter(function (skill) { return skill.subcategory_id === subcategory.id; });
            tree.push(__assign(__assign({}, subcategory), { skills: subcategorySkills }));
        });
        setSkillTree(tree);
    };
    var renderSkillTree = function () {
        if (!skillTree) {
            return React.createElement("p", null, "Loading...");
        }
        return skillTree.map(function (subcategory) {
            var subcategoryColor = mapSubcategoryToColor(subcategory.id);
            return (React.createElement(SubcategoryContainer, { key: subcategory.id },
                React.createElement(SubcategoryTitle, { color: subcategoryColor }, subcategory.name),
                React.createElement(SkillsContainer, null,
                    React.createElement(SkillList, { skills: subcategory.skills, onSkillClick: onSkillClick, isEditMode: false }))));
        });
    };
    return (React.createElement(React.Fragment, null,
        React.createElement(Filter, null),
        React.createElement(Wrapper, null,
            React.createElement(NoResults, null,
                "No results for ",
                React.createElement("i", null,
                    "\"",
                    query,
                    "\"")),
            React.createElement(Title, null, "Maybe start with these?"),
            renderSkillTree())));
};
var Wrapper = styled(FlexContainer)(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  flex-direction: column;\n  padding: 5rem 1rem;\n"], ["\n  flex-direction: column;\n  padding: 5rem 1rem;\n"])));
var NoResults = styled.p(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n  font-size: 1.6rem;\n  font-family: ", ", sans-serif;\n"], ["\n  font-size: 1.6rem;\n  font-family: ", ", sans-serif;\n"])), function (_a) {
    var theme = _a.theme;
    return theme.font.secondary;
});
var Title = styled.h1(templateObject_3 || (templateObject_3 = __makeTemplateObject(["\n  font-size: 5rem;\n  margin: 0.2rem 0 5rem;\n  font-family: ", ", sans-serif;\n  font-weight: 400;\n  line-height: 5rem;\n"], ["\n  font-size: 5rem;\n  margin: 0.2rem 0 5rem;\n  font-family: ", ", sans-serif;\n  font-weight: 400;\n  line-height: 5rem;\n"])), function (_a) {
    var theme = _a.theme;
    return theme.font.primary;
});
var SubcategoryContainer = styled.div(templateObject_4 || (templateObject_4 = __makeTemplateObject([""], [""])));
var SubcategoryTitle = styled.h3(templateObject_5 || (templateObject_5 = __makeTemplateObject(["\n  font-family: ", ", sans-serif;\n  font-weight: bold;\n  font-size: 1.6rem;\n  letter-spacing: 0.03rem;\n  color: ", ";\n  margin-bottom: 2rem;\n"], ["\n  font-family: ", ", sans-serif;\n  font-weight: bold;\n  font-size: 1.6rem;\n  letter-spacing: 0.03rem;\n  color: ", ";\n  margin-bottom: 2rem;\n"])), function (_a) {
    var theme = _a.theme;
    return theme.font.secondary;
}, function (_a) {
    var color = _a.color;
    return color;
});
var SkillsContainer = styled.div(templateObject_6 || (templateObject_6 = __makeTemplateObject(["\n  display: flex;\n  margin: 1.2rem 0 2.8rem 0;\n  flex-wrap: wrap;\n"], ["\n  display: flex;\n  margin: 1.2rem 0 2.8rem 0;\n  flex-wrap: wrap;\n"])));
export var SearchResultEmpty = observer(SearchResultEmptyComponent);
var templateObject_1, templateObject_2, templateObject_3, templateObject_4, templateObject_5, templateObject_6;
