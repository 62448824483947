var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
import React from 'react';
import styled from 'styled-components';
export var Category = function (_a) {
    var name = _a.name, children = _a.children;
    return (React.createElement(Wrapper, null,
        React.createElement(Title, null, name),
        React.createElement(ChildsWrapper, null, children)));
};
var Wrapper = styled.div(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  display: block;\n  margin: 1rem 0 0 0;\n"], ["\n  display: block;\n  margin: 1rem 0 0 0;\n"])));
var Title = styled.h2(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n  color: ", ";\n  font-family: ", ";\n  font-size: 2.5rem;\n  margin-bottom: 0;\n"], ["\n  color: ", ";\n  font-family: ", ";\n  font-size: 2.5rem;\n  margin-bottom: 0;\n"])), function (_a) {
    var theme = _a.theme;
    return theme.color.txt4;
}, function (_a) {
    var theme = _a.theme;
    return theme.font.secondary;
});
var ChildsWrapper = styled.div(templateObject_3 || (templateObject_3 = __makeTemplateObject(["\n  display: block;\n"], ["\n  display: block;\n"])));
var templateObject_1, templateObject_2, templateObject_3;
