export var ID;
(function (ID) {
    ID["ROOT"] = "root";
})(ID || (ID = {}));
/**
 * Current API version
 */
export var API_VERSION = 1;
export var APP_URL = "".concat(process.env.API_BASE_URL, "/v").concat(API_VERSION);
export var SortOrder;
(function (SortOrder) {
    SortOrder[SortOrder["None"] = 0] = "None";
    SortOrder[SortOrder["Ascending"] = 1] = "Ascending";
    SortOrder[SortOrder["Descending"] = 2] = "Descending";
})(SortOrder || (SortOrder = {}));
export var Pages;
(function (Pages) {
    Pages["Unset"] = "";
    Pages["Login"] = "/login";
    Pages["Dashboard"] = "/";
    Pages["Profile"] = "/profile";
    Pages["Skill"] = "/skill";
    Pages["Person"] = "/person";
})(Pages || (Pages = {}));
export var SearchItemType;
(function (SearchItemType) {
    SearchItemType[SearchItemType["Person"] = 0] = "Person";
    SearchItemType[SearchItemType["Skill"] = 1] = "Skill";
    SearchItemType[SearchItemType["Other"] = 2] = "Other";
    SearchItemType[SearchItemType["Subcategory"] = 3] = "Subcategory";
})(SearchItemType || (SearchItemType = {}));
export var Theme;
(function (Theme) {
    Theme["Light"] = "light";
    Theme["Dark"] = "dark";
})(Theme || (Theme = {}));
export var DataType;
(function (DataType) {
    DataType["Users"] = "users";
    DataType["Skills"] = "skills";
    DataType["UserSkills"] = "user_skills";
    DataType["Categories"] = "categories";
    DataType["Subcategories"] = "subcategories";
    DataType["RecentSearches"] = "recent_searches";
    DataType["AccessToken"] = "id_token";
})(DataType || (DataType = {}));
export var UserRole;
(function (UserRole) {
    UserRole["None"] = "";
    UserRole["Sales"] = "sales";
    UserRole["Designer"] = "designer";
    UserRole["Developer"] = "developer";
    UserRole["Support"] = "support";
    UserRole["Hox"] = "hox";
})(UserRole || (UserRole = {}));
export var SkillLevel;
(function (SkillLevel) {
    SkillLevel[SkillLevel["None"] = 0] = "None";
    SkillLevel[SkillLevel["Basics"] = 1] = "Basics";
    SkillLevel[SkillLevel["Good"] = 2] = "Good";
    SkillLevel[SkillLevel["Fluent"] = 3] = "Fluent";
})(SkillLevel || (SkillLevel = {}));
