var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
import React from 'react';
import styled from 'styled-components';
import { FaRegUser, FaCube, FaFilter, FaFolderOpen } from 'react-icons/fa';
import { SearchItemType } from 'app/constants';
import Highlighter from 'react-highlight-words';
export var DropdownContent = function (_a) {
    var title = _a.title, items = _a.items, onItemSelect = _a.onItemSelect;
    var selectedItem;
    var renderIcon = function (itemType) {
        var iconStyle = {
            height: '2rem',
        };
        switch (itemType) {
            case SearchItemType.Person:
                return React.createElement(ItemIconPerson, { style: iconStyle });
            case SearchItemType.Skill:
                return React.createElement(ItemIconSkill, { style: iconStyle });
            case SearchItemType.Subcategory:
                return React.createElement(ItemIconSubcategory, { style: iconStyle });
            case SearchItemType.Other:
                return React.createElement(ItemIconOther, { style: iconStyle });
            default:
                return null;
        }
    };
    var renderItemField = function (field, item) {
        if (item.highlight && item.highlight.field === field) {
            return (React.createElement(Highlighter, { highlightClassName: "highlighted-text", searchWords: item.highlight.words, autoEscape: true, textToHighlight: item[field] }));
        }
        return item[field];
    };
    var onItemFocus = function (item) {
        selectedItem = item;
    };
    var onItemKeyDown = function (event) {
        if (onItemSelect && event.keyCode === 13) {
            onItemSelect(selectedItem);
        }
    };
    var renderItems = function () {
        if (items) {
            return items.map(function (item, index) { return (React.createElement(Item, { key: "".concat(item.title, "-").concat(item.id, "-").concat(index), tabIndex: 0, onFocus: function () { return onItemFocus(item); }, onClick: function () { return onItemSelect(item); }, onKeyDown: onItemKeyDown },
                renderIcon(item.type),
                React.createElement(TextColumn, null,
                    React.createElement(ItemTitle, null, renderItemField('title', item)),
                    item.description && (React.createElement(ItemDesc, null, renderItemField('description', item)))))); });
        }
        return null;
    };
    return (React.createElement(React.Fragment, null,
        title && React.createElement(Title, null, title),
        React.createElement(List, null, renderItems())));
};
var Title = styled.h3(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  color: ", ";\n  font-size: 1.6rem;\n  font-family: ", ";\n  margin-top: 0;\n"], ["\n  color: ", ";\n  font-size: 1.6rem;\n  font-family: ", ";\n  margin-top: 0;\n"])), function (_a) {
    var theme = _a.theme;
    return theme.color.txt3;
}, function (_a) {
    var theme = _a.theme;
    return theme.font.secondary;
});
var List = styled.ul(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n  list-style: none;\n  padding: 0;\n  margin: 0;\n"], ["\n  list-style: none;\n  padding: 0;\n  margin: 0;\n"])));
var Item = styled.li(templateObject_3 || (templateObject_3 = __makeTemplateObject(["\n  display: flex;\n  align-items: center;\n  margin: 0 0 1rem -0.8rem;\n  cursor: pointer;\n  padding: 0.8rem;\n  font-size: 1.4rem;\n  :focus,\n  :hover {\n    background: ", ";\n  }\n"], ["\n  display: flex;\n  align-items: center;\n  margin: 0 0 1rem -0.8rem;\n  cursor: pointer;\n  padding: 0.8rem;\n  font-size: 1.4rem;\n  :focus,\n  :hover {\n    background: ", ";\n  }\n"])), function (_a) {
    var theme = _a.theme;
    return theme.color.bgCardHover;
});
var ItemTitle = styled.span(templateObject_4 || (templateObject_4 = __makeTemplateObject(["\n  display: flex;\n  align-items: center;\n  color: ", ";\n  font-family: ", ";\n  font-size: 1.6rem;\n  font-weight: bold;\n"], ["\n  display: flex;\n  align-items: center;\n  color: ", ";\n  font-family: ", ";\n  font-size: 1.6rem;\n  font-weight: bold;\n"])), function (_a) {
    var theme = _a.theme;
    return theme.color.txt2;
}, function (_a) {
    var theme = _a.theme;
    return theme.font.secondary;
});
var ItemIconPerson = styled(FaRegUser)(templateObject_5 || (templateObject_5 = __makeTemplateObject([""], [""])));
var ItemIconSkill = styled(FaCube)(templateObject_6 || (templateObject_6 = __makeTemplateObject([""], [""])));
var ItemIconOther = styled(FaFilter)(templateObject_7 || (templateObject_7 = __makeTemplateObject([""], [""])));
var ItemIconSubcategory = styled(FaFolderOpen)(templateObject_8 || (templateObject_8 = __makeTemplateObject([""], [""])));
var TextColumn = styled.div(templateObject_9 || (templateObject_9 = __makeTemplateObject(["\n  display: flex;\n  flex-wrap: nowrap;\n  flex-direction: row;\n  align-items: center;\n  margin-left: 0.9rem;\n\n  ", "\n"], ["\n  display: flex;\n  flex-wrap: nowrap;\n  flex-direction: row;\n  align-items: center;\n  margin-left: 0.9rem;\n\n  ", "\n"])), function (_a) {
    var theme = _a.theme;
    return theme.onMobile("\n      flex-wrap: wrap;\n      flex-direction: column;\n      align-items: flex-start;\n    ");
});
var ItemDesc = styled.span(templateObject_10 || (templateObject_10 = __makeTemplateObject(["\n  color: #999;\n  font-family: ", ";\n  font-weight: bold;\n  font-size: 1.2rem;\n  margin-left: 0.7rem;\n\n  ", "\n"], ["\n  color: #999;\n  font-family: ", ";\n  font-weight: bold;\n  font-size: 1.2rem;\n  margin-left: 0.7rem;\n\n  ", "\n"])), function (_a) {
    var theme = _a.theme;
    return theme.font.secondary;
}, function (_a) {
    var theme = _a.theme;
    return theme.onMobile("\n      margin-left: 0;\n      margin-top: 0.5rem;\n    ");
});
var templateObject_1, templateObject_2, templateObject_3, templateObject_4, templateObject_5, templateObject_6, templateObject_7, templateObject_8, templateObject_9, templateObject_10;
