var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
import React from 'react';
import styled from 'styled-components';
import Markdown from 'react-markdown';
import remarkGfm from 'remark-gfm';
import { FaMobileAlt, FaEnvelope, FaAddressCard } from 'react-icons/fa';
export var ProfileDetails = function (_a) {
    var user = _a.user;
    var name = user.name, description = user.description, phone = user.phone_number, title = user.title, email = user.email, cv = user.cv;
    var renderDescription = function () {
        if (description && description.length) {
            return (React.createElement(Markdown, { remarkPlugins: [remarkGfm], linkTarget: "_blank" }, description));
        }
        return '<description missing>';
    };
    var renderPhoneNumber = function () {
        if (phone && phone.length) {
            var cleanedPhoneNumber = phone.replace(' ', '');
            return React.createElement("a", { href: "tel:".concat(cleanedPhoneNumber) }, phone);
        }
        return '<phone number missing>';
    };
    return (React.createElement(React.Fragment, null,
        React.createElement(UserName, null, name),
        React.createElement(UserTitle, null, title || '<title missing>'),
        React.createElement(ContactWrapper, null,
            React.createElement(UserPhone, null,
                React.createElement(MobileIcon, null),
                React.createElement(ContactValue, null, renderPhoneNumber())),
            React.createElement(UserEmail, null,
                React.createElement(FaEnvelope, null),
                React.createElement(ContactValue, null,
                    React.createElement("a", { target: "_blank", rel: "noopener noreferrer", href: "mailto:".concat(email) }, email))),
            cv && (React.createElement(UserCV, null,
                React.createElement(CVIcon, null),
                React.createElement(ContactValue, null,
                    React.createElement("a", { target: "_blank", rel: "noopener noreferrer", href: cv }, "Open CV"))))),
        React.createElement(UserDescription, null, renderDescription())));
};
var ContactWrapper = styled.div(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  display: flex;\n  ", "\n"], ["\n  display: flex;\n  ", "\n"])), function (_a) {
    var theme = _a.theme;
    return theme.onMobile(" \n      flex-wrap: wrap;\n      flex-direction: column;\n    ");
});
var UserName = styled.h1(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n  font-size: 5rem;\n  font-weight: 400;\n  line-height: 5.1rem;\n  font-family: ", ";\n  margin: 0;\n"], ["\n  font-size: 5rem;\n  font-weight: 400;\n  line-height: 5.1rem;\n  font-family: ", ";\n  margin: 0;\n"])), function (_a) {
    var theme = _a.theme;
    return theme.font.primary;
});
var UserTitle = styled.h3(templateObject_3 || (templateObject_3 = __makeTemplateObject(["\n  font-size: 1.4rem;\n  font-weight: 800;\n  font-family: ", ";\n  text-transform: uppercase;\n  margin: 1.5rem 0;\n  letter-spacing: 0.3rem;\n"], ["\n  font-size: 1.4rem;\n  font-weight: 800;\n  font-family: ", ";\n  text-transform: uppercase;\n  margin: 1.5rem 0;\n  letter-spacing: 0.3rem;\n"])), function (_a) {
    var theme = _a.theme;
    return theme.font.secondary;
});
var UserPhone = styled.div(templateObject_4 || (templateObject_4 = __makeTemplateObject(["\n  display: inline-flex;\n  align-items: center;\n  font-family: ", ";\n  font-weight: 500;\n  font-size: 2rem;\n"], ["\n  display: inline-flex;\n  align-items: center;\n  font-family: ", ";\n  font-weight: 500;\n  font-size: 2rem;\n"])), function (_a) {
    var theme = _a.theme;
    return theme.font.primary;
});
var UserEmail = styled.div(templateObject_5 || (templateObject_5 = __makeTemplateObject(["\n  display: inline-flex;\n  align-items: center;\n  margin-left: 3rem;\n  font-family: ", ";\n  font-weight: 500;\n  font-size: 2rem;\n\n  ", "\n"], ["\n  display: inline-flex;\n  align-items: center;\n  margin-left: 3rem;\n  font-family: ", ";\n  font-weight: 500;\n  font-size: 2rem;\n\n  ", "\n"])), function (_a) {
    var theme = _a.theme;
    return theme.font.primary;
}, function (_a) {
    var theme = _a.theme;
    return theme.onMobile(" \n      margin-left: 0;\n      margin-top: 1rem;\n    ");
});
var UserCV = styled.div(templateObject_6 || (templateObject_6 = __makeTemplateObject(["\n  display: inline-flex;\n  align-items: center;\n  margin-left: 3rem;\n  font-family: ", ";\n  font-weight: 500;\n  font-size: 2rem;\n\n  ", "\n"], ["\n  display: inline-flex;\n  align-items: center;\n  margin-left: 3rem;\n  font-family: ", ";\n  font-weight: 500;\n  font-size: 2rem;\n\n  ", "\n"])), function (_a) {
    var theme = _a.theme;
    return theme.font.primary;
}, function (_a) {
    var theme = _a.theme;
    return theme.onMobile(" \n      margin-left: 0;\n      margin-top: 1rem;\n    ");
});
var MobileIcon = styled(FaMobileAlt)(templateObject_7 || (templateObject_7 = __makeTemplateObject(["\n  color: ", ";\n"], ["\n  color: ", ";\n"])), function (_a) {
    var theme = _a.theme;
    return theme.color.txt1;
});
var CVIcon = styled(FaAddressCard)(templateObject_8 || (templateObject_8 = __makeTemplateObject(["\n  color: ", ";\n"], ["\n  color: ", ";\n"])), function (_a) {
    var theme = _a.theme;
    return theme.color.txt1;
});
var ContactValue = styled.div(templateObject_9 || (templateObject_9 = __makeTemplateObject(["\n  margin-left: 1rem;\n\n  a {\n    text-decoration: underline;\n    color: inherit;\n  }\n"], ["\n  margin-left: 1rem;\n\n  a {\n    text-decoration: underline;\n    color: inherit;\n  }\n"])));
var UserDescription = styled.div(templateObject_10 || (templateObject_10 = __makeTemplateObject(["\n  color: ", ";\n  font-size: 1.8rem;\n  font-weight: 400;\n  margin-top: 2rem;\n\n  h1,\n  h2,\n  h3 {\n    font-family: ", ";\n  }\n\n  p,\n  * {\n    font-family: ", ";\n  }\n\n  a {\n    color: ", ";\n    word-break: break-all;\n  }\n"], ["\n  color: ", ";\n  font-size: 1.8rem;\n  font-weight: 400;\n  margin-top: 2rem;\n\n  h1,\n  h2,\n  h3 {\n    font-family: ", ";\n  }\n\n  p,\n  * {\n    font-family: ", ";\n  }\n\n  a {\n    color: ", ";\n    word-break: break-all;\n  }\n"])), function (_a) {
    var theme = _a.theme;
    return theme.color.txt1;
}, function (_a) {
    var theme = _a.theme;
    return theme.font.primary;
}, function (_a) {
    var theme = _a.theme;
    return theme.font.secondary;
}, function (_a) {
    var theme = _a.theme;
    return theme.color.link1;
});
var templateObject_1, templateObject_2, templateObject_3, templateObject_4, templateObject_5, templateObject_6, templateObject_7, templateObject_8, templateObject_9, templateObject_10;
