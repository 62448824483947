var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import React from 'react';
import styled, { css } from 'styled-components';
import { breakpoints } from './grid-settings';
export var FlexItem = function (_a) {
    var children = _a.children, rest = __rest(_a, ["children"]);
    return React.createElement(Item, __assign({}, rest), children);
};
var Item = styled.div(templateObject_3 || (templateObject_3 = __makeTemplateObject(["\n  flex: 1 1 100%;\n  ", "\n"], ["\n  flex: 1 1 100%;\n  ", "\n"])), function (props) {
    return Object.keys(breakpoints).map(function (key) {
        if (props && props[key]) {
            if (key === 'xs' && props.xs) {
                return css(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n            flex: 0 1 ", "%;\n            max-width: ", "%;\n          "], ["\n            flex: 0 1 ", "%;\n            max-width: ", "%;\n          "])), (props.xs / 12) * 100, (props.xs / 12) * 100);
            }
            return css(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n          @media (", ") {\n            flex: 0 1 ", "%;\n            max-width: ", "%;\n          }\n        "], ["\n          @media (", ") {\n            flex: 0 1 ", "%;\n            max-width: ", "%;\n          }\n        "])), breakpoints[key].mediaQuery, (props[key] / 12) * 100, (props[key] / 12) * 100);
        }
        return null;
    });
});
var templateObject_1, templateObject_2, templateObject_3;
