import React, { lazy, Suspense, useContext, useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { observer } from 'mobx-react-lite';
import { toJS } from 'mobx';
import { AppContext } from 'store';
import { SearchItemType } from 'app/constants';
import { useAnimatedLoad } from 'app/hooks';
import { Loader, FlexItem, FlexContainer } from 'app/components';
import { getSearchParamsFromQuery } from 'app/utils';
import { Filter } from 'app/components/filter';
import { SearchResultTitle } from './search-result-title';
var SearchResultList = lazy(function () {
    return import('./search-result-list').then(function (module) { return ({ default: module.SearchResultList }); });
});
var SearchResultEmpty = lazy(function () {
    return import('./search-result-empty').then(function (module) { return ({ default: module.SearchResultEmpty }); });
});
var SearchResultViewComponent = function () {
    var location = useLocation();
    var navigate = useNavigate();
    var _a = useContext(AppContext), dataStore = _a.dataStore, userStore = _a.userStore;
    var _b = useState(false), mounted = _b[0], setMounted = _b[1];
    var setCurrentSearchItem = dataStore.setCurrentSearchItem, currentSearchItem = dataStore.currentSearchItem, searchResults = dataStore.searchResults;
    useEffect(function () {
        /**
         * Fetch all needed data. This uses cache if possible.
         */
        dataStore.fetchData().then(function () {
            setCurrentSearchItem(getSearchParamsFromQuery(location.search));
            dataStore.executeSearch();
            setMounted(true);
        });
    }, [location.search]);
    var openUser = function (user) {
        // clicked own profile
        if (userStore.user && userStore.user.id === user.id) {
            navigate('/profile');
            return;
        }
        navigate("/person?".concat(user.id), {
            state: {
                user: toJS(user),
            },
        });
    };
    var onSkillClick = function (skill) {
        if (currentSearchItem.id === skill.id) {
            return;
        }
        var searchString = encodeURIComponent(skill.name);
        navigate("/?search=".concat(searchString, "&type=").concat(SearchItemType.Skill, "&id=").concat(skill.id));
    };
    var getSearchResultTitle = function () {
        var keyword = currentSearchItem.query;
        var matchCount = searchResults.length;
        if (keyword.length === 0) {
            return "Showing all ".concat(matchCount, " Qvikies");
        }
        return "Your search for \"".concat(keyword, "\" matches ").concat(matchCount, " Qvikie(s)");
    };
    if (!mounted) {
        return React.createElement(Loader, { title: "Loading..." });
    }
    return (React.createElement(React.Fragment, null,
        !!searchResults.length && (React.createElement(FlexContainer, null,
            React.createElement(FlexItem, { xs: 12 },
                React.createElement(Filter, null),
                React.createElement(SearchResultTitle, { title: getSearchResultTitle() })))),
        React.createElement(Suspense, { fallback: React.createElement(Loader, { title: "Loading users..." }) }, !searchResults.length ? (React.createElement(SearchResultEmpty, { onSkillClick: onSkillClick, query: currentSearchItem.query })) : (React.createElement(SearchResultList, { searchResults: searchResults, onItemClick: openUser, onSkillClick: onSkillClick, currentSearchItem: currentSearchItem })))));
};
export var SearchResultView = useAnimatedLoad(observer(SearchResultViewComponent));
