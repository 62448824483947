var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __metadata = (this && this.__metadata) || function (k, v) {
    if (typeof Reflect === "object" && typeof Reflect.metadata === "function") return Reflect.metadata(k, v);
};
import { observable, action, makeObservable } from 'mobx';
import DataStorage from 'app/store/data-storage';
import { SortOrder, Theme } from '../constants';
export var PortalStore = /** @class */ (function () {
    function PortalStore() {
        var _this = this;
        Object.defineProperty(this, "listSortOrder", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: SortOrder.Ascending
        });
        Object.defineProperty(this, "editMode", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: false
        });
        Object.defineProperty(this, "myProfileExpandSubcategory", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: 0
        });
        Object.defineProperty(this, "theme", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: DataStorage.getPersistItem('theme') || Theme.Light
        });
        Object.defineProperty(this, "error", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
        Object.defineProperty(this, "setListSortOrder", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: function (order) {
                _this.listSortOrder = order;
            }
        });
        Object.defineProperty(this, "setExpandableSubcategory", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: function (id) {
                _this.myProfileExpandSubcategory = id;
            }
        });
        Object.defineProperty(this, "setEditMode", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: function (mode) {
                _this.editMode = mode;
            }
        });
        Object.defineProperty(this, "setTheme", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: function (theme) {
                _this.theme = theme;
                DataStorage.setPersistItem('theme', theme);
            }
        });
        Object.defineProperty(this, "setError", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: function (error) {
                _this.error = error;
            }
        });
        makeObservable(this);
    }
    __decorate([
        observable,
        __metadata("design:type", Number)
    ], PortalStore.prototype, "listSortOrder", void 0);
    __decorate([
        observable,
        __metadata("design:type", Object)
    ], PortalStore.prototype, "editMode", void 0);
    __decorate([
        observable,
        __metadata("design:type", Object)
    ], PortalStore.prototype, "myProfileExpandSubcategory", void 0);
    __decorate([
        observable,
        __metadata("design:type", String)
    ], PortalStore.prototype, "theme", void 0);
    __decorate([
        observable,
        __metadata("design:type", Object)
    ], PortalStore.prototype, "error", void 0);
    __decorate([
        action,
        __metadata("design:type", Object)
    ], PortalStore.prototype, "setListSortOrder", void 0);
    __decorate([
        action,
        __metadata("design:type", Object)
    ], PortalStore.prototype, "setExpandableSubcategory", void 0);
    __decorate([
        action,
        __metadata("design:type", Object)
    ], PortalStore.prototype, "setEditMode", void 0);
    __decorate([
        action,
        __metadata("design:type", Object)
    ], PortalStore.prototype, "setTheme", void 0);
    __decorate([
        action,
        __metadata("design:type", Object)
    ], PortalStore.prototype, "setError", void 0);
    return PortalStore;
}());
export var portalStore = new PortalStore();
