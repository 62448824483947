var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
import React from 'react';
import styled from 'styled-components';
import { FlexContainer, FlexItem } from 'components/grid';
import { SearchResultItem } from './search-result-item';
export var SearchResultList = function (_a) {
    var currentSearchItem = _a.currentSearchItem, onItemClick = _a.onItemClick, onSkillClick = _a.onSkillClick, searchResults = _a.searchResults;
    return (React.createElement(Container, null, searchResults.map(function (searchResultItem) { return (React.createElement(FlexItem, { xl: 6, key: searchResultItem.id },
        React.createElement(ItemWrapper, null,
            React.createElement(SearchResultItem, { resultItem: searchResultItem, onItemClick: onItemClick, currentSearchItem: currentSearchItem, onSkillClick: onSkillClick })))); })));
};
var Container = styled(FlexContainer)(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  ", "\n"], ["\n  ", "\n"])), function (_a) {
    var theme = _a.theme;
    return theme.onMobile("\n    margin-top: 2rem;\n  ");
});
var ItemWrapper = styled.div(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n  padding: 0 1rem 2rem 1rem;\n\n  ", "\n"], ["\n  padding: 0 1rem 2rem 1rem;\n\n  ", "\n"])), function (_a) {
    var theme = _a.theme;
    return theme.onMobile(" \n      padding: 0 0 1rem 0;\n    ");
});
export default SearchResultList;
var templateObject_1, templateObject_2;
