var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
export var useAnimatedLoad = function (WrappedComponent) {
    return function animated(_a) {
        var props = __rest(_a, []);
        var _b = useState(false), timeout = _b[0], timedOut = _b[1];
        var timer;
        useEffect(function () {
            if (!timeout) {
                timer = setTimeout(function () {
                    timedOut(true);
                }, 0.7);
            }
            return function () {
                if (timer) {
                    clearTimeout(timer);
                }
            };
        }, [timeout]);
        return (React.createElement(AnimatedContainer, { className: "fade-in ".concat(timeout && 'visible') },
            React.createElement(WrappedComponent, __assign({}, props))));
    };
};
var AnimatedContainer = styled.div(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  &.fade-in {\n    opacity: 0;\n  }\n  &.fade-in.visible {\n    transition: opacity 0.15s ease-in;\n    opacity: 1;\n  }\n"], ["\n  &.fade-in {\n    opacity: 0;\n  }\n  &.fade-in.visible {\n    transition: opacity 0.15s ease-in;\n    opacity: 1;\n  }\n"])));
var templateObject_1;
