import { network } from './networking';
var getAll = function () {
    return network.httpGet('/users/').then(function (response) { return response.data; });
};
var getProfile = function () {
    return network.httpGet('/users/me').then(function (response) { return response.data; });
};
var updateProfile = function (fields) {
    var payload = {
        title: fields.title,
        phone_number: fields.phone,
        description: fields.description,
        role: fields.role,
        cv: fields.cv,
        skills: fields.skills || [],
    };
    return network.httpPatch('/users/me', payload).then(function (response) { return response.data; });
};
var getUserSkills = function (userId) {
    return network.httpGet("/users/".concat(userId, "/skills")).then(function (response) { return response.data; });
};
var removeUserSkill = function (userId, skillId) {
    var payload = {
        user_id: userId,
        skill_id: skillId,
    };
    return network
        .httpDelete("/users/me/skills/".concat(skillId), { data: payload })
        .then(function (response) { return response.data; });
};
var addUserSkill = function (skillId, score) {
    var payload = {
        score: score,
    };
    return network
        .httpPost("/users/me/skills/".concat(skillId), payload)
        .then(function (response) { return response.data; });
};
var updateUserSkill = function (skillId, score) {
    var payload = {
        score: score,
    };
    return network
        .httpPatch("/users/me/skills/".concat(skillId), payload)
        .then(function (response) { return response.data; });
};
var getUserSkillsTree = function (userId) {
    return network.httpGet("/users/".concat(userId, "/skills/tree")).then(function (response) { return response.data; });
};
export var userApi = {
    addUserSkill: addUserSkill,
    getAll: getAll,
    getProfile: getProfile,
    getUserSkills: getUserSkills,
    getUserSkillsTree: getUserSkillsTree,
    removeUserSkill: removeUserSkill,
    updateProfile: updateProfile,
    updateUserSkill: updateUserSkill,
};
