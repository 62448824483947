var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
import React, { useContext } from 'react';
import styled from 'styled-components';
import { AppContext } from 'store';
import { observer } from 'mobx-react-lite';
import { useLocation, useNavigate } from 'react-router-dom';
import qs from 'query-string';
import { getSearchParamsFromQuery } from 'app/utils/helpers';
export var LocationFilterComponent = function () {
    var navigation = useNavigate();
    var routerLocation = useLocation();
    var dataStore = useContext(AppContext).dataStore;
    var getAllUserLocations = dataStore.getAllUserLocations;
    var _a = getSearchParamsFromQuery(routerLocation.search), team = _a.team, location = _a.location, query = _a.query, type = _a.type, id = _a.id;
    var locations = getAllUserLocations();
    var onClick = function (item) {
        if (item === location)
            return;
        var queryParams = {
            search: query,
            type: type,
        };
        if (id) {
            queryParams.id = id;
        }
        if (team) {
            queryParams.team = team;
        }
        if (item && item !== 'all locations') {
            queryParams.location = item;
        }
        var queryString = qs.stringify(queryParams);
        navigation("/?".concat(queryString));
    };
    var renderPills = function (items) {
        return items.map(function (item, index) {
            var isActive = !!((location && location === item.key) ||
                (!location && item.key === 'all locations'));
            return (React.createElement(PillItem, { key: index, active: isActive, onClick: function () { return onClick(item.key); } },
                item.key,
                " (",
                item.value,
                ")"));
        });
    };
    return React.createElement(Container, null, renderPills(locations));
};
var Container = styled.div(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  display: flex;\n  flex-direction: row;\n  align-items: center;\n  justify-content: center;\n  flex-wrap: wrap;\n"], ["\n  display: flex;\n  flex-direction: row;\n  align-items: center;\n  justify-content: center;\n  flex-wrap: wrap;\n"])));
var PillItem = styled.button(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n  font-family: ", ";\n  font-size: 1rem;\n  font-weight: 700;\n  letter-spacing: 0.2rem;\n  cursor: pointer;\n  padding: 0.8rem 1rem;\n  background-color: ", ";\n  color: ", ";\n  border: none;\n  margin: 0.3rem;\n  text-transform: uppercase;\n"], ["\n  font-family: ", ";\n  font-size: 1rem;\n  font-weight: 700;\n  letter-spacing: 0.2rem;\n  cursor: pointer;\n  padding: 0.8rem 1rem;\n  background-color: ", ";\n  color: ", ";\n  border: none;\n  margin: 0.3rem;\n  text-transform: uppercase;\n"])), function (_a) {
    var theme = _a.theme;
    return theme.font.secondary;
}, function (_a) {
    var active = _a.active, theme = _a.theme;
    return active ? theme.color.txt1 : theme.color.bgPill;
}, function (_a) {
    var active = _a.active, theme = _a.theme;
    return active ? theme.color.txtInverted : theme.color.txtPill;
});
export var LocationFilter = observer(LocationFilterComponent);
var templateObject_1, templateObject_2;
