var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
import React from 'react';
import styled from 'styled-components';
import { SkillLevel } from 'components/skill-level';
export var SkillLevelInfo = function () {
    var color = '#818181';
    return (React.createElement(Wrapper, null,
        React.createElement(Info, null, "Expertise"),
        React.createElement(Info, null,
            React.createElement(SkillLevel, { size: "2em", fillColor: color, skillLevel: 1 }),
            "Basics"),
        React.createElement(Info, null,
            React.createElement(SkillLevel, { size: "2em", fillColor: color, skillLevel: 2 }),
            "Good"),
        React.createElement(Info, null,
            React.createElement(SkillLevel, { size: "2em", fillColor: color, skillLevel: 3 }),
            "Fluent")));
};
var Wrapper = styled.div(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  display: flex;\n  align-items: center;\n"], ["\n  display: flex;\n  align-items: center;\n"])));
var Info = styled.span(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n  color: ", ";\n  display: inline-flex;\n  align-items: center;\n  font-family: ", ";\n  font-weight: bold;\n  font-size: 1.6rem;\n  margin-right: 2rem;\n  height: 2rem;\n"], ["\n  color: ", ";\n  display: inline-flex;\n  align-items: center;\n  font-family: ", ";\n  font-weight: bold;\n  font-size: 1.6rem;\n  margin-right: 2rem;\n  height: 2rem;\n"])), function (_a) {
    var theme = _a.theme;
    return theme.color.txt3;
}, function (_a) {
    var theme = _a.theme;
    return theme.font.secondary;
});
var templateObject_1, templateObject_2;
