var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
import React from 'react';
import { FaSync } from 'react-icons/fa';
import styled, { keyframes } from 'styled-components';
export var Loader = function (_a) {
    var _b = _a.title, title = _b === void 0 ? 'Loading...' : _b;
    var renderContent = function () {
        var iconStyle = { fontSize: '4rem' };
        return (React.createElement(React.Fragment, null,
            React.createElement(LoadingIcon, { style: iconStyle }),
            React.createElement(Title, null, title)));
    };
    return React.createElement(Container, null, renderContent());
};
var Container = styled.div(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  display: flex;\n  margin-top: 4rem;\n  flex-direction: column;\n  align-content: center;\n  align-items: center;\n  justify-content: center;\n"], ["\n  display: flex;\n  margin-top: 4rem;\n  flex-direction: column;\n  align-content: center;\n  align-items: center;\n  justify-content: center;\n"])));
var RotationAnimation = keyframes(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n  0% {\n    transform: rotate(0deg);\n  }\n  100% {\n    transform: rotate(360deg);\n  }\n"], ["\n  0% {\n    transform: rotate(0deg);\n  }\n  100% {\n    transform: rotate(360deg);\n  }\n"])));
var LoadingIcon = styled(FaSync)(templateObject_3 || (templateObject_3 = __makeTemplateObject(["\n  color: ", ";\n  animation: ", " 2s linear 0s infinite;\n"], ["\n  color: ", ";\n  animation: ", " 2s linear 0s infinite;\n"])), function (_a) {
    var theme = _a.theme;
    return theme.color.txt1;
}, RotationAnimation);
var Title = styled.h3(templateObject_4 || (templateObject_4 = __makeTemplateObject(["\n  margin-top: 2rem;\n  font-size: 1.6rem;\n  font-family: ", ";\n  color: ", ";\n"], ["\n  margin-top: 2rem;\n  font-size: 1.6rem;\n  font-family: ", ";\n  color: ", ";\n"])), function (_a) {
    var theme = _a.theme;
    return theme.font.primary;
}, function (_a) {
    var theme = _a.theme;
    return theme.color.txt2;
});
var templateObject_1, templateObject_2, templateObject_3, templateObject_4;
