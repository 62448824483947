import { SortOrder, SearchItemType } from 'app/constants';
import qs from 'query-string';
/**
 * Escape all characters from string that
 * would brake regexp clause
 *
 * @param str string to escape
 */
export var escapeRegExp = function (str) {
    return str.replace(/[-/\\^$*+?.()|[\]{}]/g, '\\$&');
};
/**
 * Check if string matches to another
 *
 * @param needle Needle string
 * @param haystack Haystack string
 */
export var matchAll = function (needle, haystack) {
    var escapedNeedle = escapeRegExp(needle);
    var regMultiChar = new RegExp("(^".concat(escapedNeedle, "$)|(").concat(escapedNeedle, ")"), 'gi');
    var regSingleChar = new RegExp("\\b(".concat(escapedNeedle, ")\\b"), 'gi');
    var matches = [];
    var tmpMatches;
    var searchType = function (type) {
        while ((tmpMatches = type.exec(haystack)) !== null) {
            if (tmpMatches[1]) {
                matches.push({ order: 0, index: 0, length: needle.length });
            }
            if (tmpMatches[2]) {
                matches.push({
                    order: 1 + tmpMatches.index,
                    index: tmpMatches.index,
                    length: needle.length,
                });
            }
        }
    };
    needle.length <= 1 ? searchType(regSingleChar) : searchType(regMultiChar);
    return matches.length ? matches : null;
};
export var sortArrayByField = function (arr, fieldName, dir) {
    if (dir === void 0) { dir = SortOrder.Ascending; }
    return arr.sort(function (a, b) {
        if (a[fieldName] > b[fieldName]) {
            return dir === SortOrder.Ascending ? 1 : -1;
        }
        if (a[fieldName] < b[fieldName]) {
            return dir === SortOrder.Ascending ? -1 : 1;
        }
        return 0;
    });
};
export var getSearchParamsFromQuery = function (queryString) {
    var parsed = qs.parse(queryString);
    var query = parsed.search ? parsed.search.toString() : '';
    var type = parsed.type ? Number(parsed.type) : SearchItemType.Other;
    var id = parsed.id ? Number(parsed.id) : undefined;
    var team = parsed.team ? parsed.team.toString() : '';
    var location = parsed.location ? parsed.location.toString() : '';
    return { query: query, type: type, id: id, team: team, location: location };
};
export var isValidURL = function (url, secure) {
    if (secure === void 0) { secure = true; }
    try {
        var val = new URL(url);
        return secure ? val.protocol === 'https:' : true;
    }
    catch (_err) {
        return false;
    }
};
