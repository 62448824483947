var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
import { observer } from 'mobx-react-lite';
import React, { useContext, useEffect, useState } from 'react';
import { FaExclamationTriangle, FaSync } from 'react-icons/fa';
import { AppContext } from 'store';
import styled from 'styled-components';
import { IconButton } from 'components/icon-button';
var MAX_ERROR_MESSAGE_LENGTH = 160;
var ErrorMessageComponent = function () {
    var portalStore = useContext(AppContext).portalStore;
    var _a = useState(false), isOpen = _a[0], setOpen = _a[1];
    var error = portalStore.error;
    useEffect(function () {
        if (error) {
            setTimeout(function () { return setOpen(true); }, 100);
        }
    }, [error]);
    var reloadWindow = function () {
        window.location.reload();
    };
    var getMessage = function () {
        if (error && error.reason && error.reason.length > MAX_ERROR_MESSAGE_LENGTH) {
            return "".concat(error.reason.slice(0, MAX_ERROR_MESSAGE_LENGTH), "...");
        }
        return error && error.reason;
    };
    if (!error) {
        return null;
    }
    return (React.createElement(Container, { open: isOpen },
        React.createElement(IconContainer, null,
            React.createElement(FaExclamationTriangle, { color: "#fff", size: "4rem" })),
        React.createElement(Content, null,
            React.createElement("h2", null, error.title),
            React.createElement("h4", null,
                "Code: ",
                error.code),
            error.reason && React.createElement("p", null, getMessage())),
        React.createElement(ReloadButton, { title: "Reload page", onClick: reloadWindow, Icon: FaSync, iconStyle: { width: '1.5rem', height: '1.5rem' } })));
};
var Container = styled.div(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  position: fixed;\n  z-index: 100;\n  top: 1rem;\n  right: 1rem;\n  left: 1rem;\n  height: ", ";\n  min-height: ", ";\n  max-height: 13rem;\n  overflow: hidden;\n  display: flex;\n  align-items: center;\n  background: #990000;\n  color: #fff;\n  padding: ", ";\n  font-family: ", ";\n  transition: all 0.5s ease-out;\n"], ["\n  position: fixed;\n  z-index: 100;\n  top: 1rem;\n  right: 1rem;\n  left: 1rem;\n  height: ", ";\n  min-height: ", ";\n  max-height: 13rem;\n  overflow: hidden;\n  display: flex;\n  align-items: center;\n  background: #990000;\n  color: #fff;\n  padding: ", ";\n  font-family: ", ";\n  transition: all 0.5s ease-out;\n"])), function (_a) {
    var open = _a.open;
    return (open ? 'auto' : '0');
}, function (_a) {
    var open = _a.open;
    return (open ? '10rem' : '0');
}, function (_a) {
    var open = _a.open;
    return (open ? '2rem' : '0');
}, function (_a) {
    var theme = _a.theme;
    return theme.font.primary;
});
var IconContainer = styled.div(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n  flex: 0 0 6rem;\n"], ["\n  flex: 0 0 6rem;\n"])));
var Content = styled.div(templateObject_3 || (templateObject_3 = __makeTemplateObject(["\n  flex: 1;\n  padding: 0 2rem 0 0;\n  h2 {\n    font-size: 2rem;\n    margin: 0;\n  }\n  h4 {\n    font-size: 1.2rem;\n    font-weight: bold;\n    text-transform: uppercase;\n    margin: 0.4rem 0 0 0;\n  }\n  p {\n    color: #ccc;\n    font-size: 1.4rem;\n    margin: 0.4rem 0;\n    max-width: 60rem;\n  }\n"], ["\n  flex: 1;\n  padding: 0 2rem 0 0;\n  h2 {\n    font-size: 2rem;\n    margin: 0;\n  }\n  h4 {\n    font-size: 1.2rem;\n    font-weight: bold;\n    text-transform: uppercase;\n    margin: 0.4rem 0 0 0;\n  }\n  p {\n    color: #ccc;\n    font-size: 1.4rem;\n    margin: 0.4rem 0;\n    max-width: 60rem;\n  }\n"])));
var ReloadButton = styled(IconButton)(templateObject_4 || (templateObject_4 = __makeTemplateObject(["\n  font-size: 1.4rem;\n"], ["\n  font-size: 1.4rem;\n"])));
export var ErrorMessage = observer(ErrorMessageComponent);
var templateObject_1, templateObject_2, templateObject_3, templateObject_4;
