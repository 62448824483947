var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
import React from 'react';
import styled from 'styled-components';
export var Footer = function (_a) {
    var versionNumber = _a.versionNumber;
    return (React.createElement(Container, null,
        React.createElement(Version, null, "Version: ".concat(versionNumber))));
};
var Container = styled.div(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  display: flex;\n  align-items: center;\n  justify-content: center;\n"], ["\n  display: flex;\n  align-items: center;\n  justify-content: center;\n"])));
var Version = styled.p(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n  color: ", ";\n  font-family: ", ";\n  font-size: 1.2rem;\n  margin: 3rem auto;\n"], ["\n  color: ", ";\n  font-family: ", ";\n  font-size: 1.2rem;\n  margin: 3rem auto;\n"])), function (_a) {
    var theme = _a.theme;
    return theme.color.txt4;
}, function (_a) {
    var theme = _a.theme;
    return theme.font.primary;
});
var templateObject_1, templateObject_2;
