import { useState, useEffect } from 'react';
import { network } from 'app/api';
import analytics, { GTMEventGategory, GTMEventType } from 'utils/analytics';
import { firebaseAuth } from '../utils/firebase';
export var useAuth = function () {
    var _a = useState(), idToken = _a[0], setIdToken = _a[1];
    var _b = useState(false), isLoggedIn = _b[0], setIsLoggedIn = _b[1];
    var _c = useState(true), isLoading = _c[0], setIsLoading = _c[1];
    useEffect(function () {
        setIsLoading(true);
        /**
         * Adds an observer for changes to the signed-in user's ID token,
         * which includes sign-in, sign-out, and token refresh events.
         */
        var unregisterAuthListener = firebaseAuth.onIdTokenChanged(function (user) {
            if (user) {
                /**
                 * If we have user => get the token. If token has expired, this will automatically
                 * refresh the token.
                 */
                user
                    .getIdToken()
                    .then(function (token) {
                    setIdToken(token);
                    network.setAccessToken(token);
                    setIsLoggedIn(true);
                })
                    .finally(function () {
                    // TODO: handle errors
                    setIsLoading(false);
                });
            }
            else {
                setIsLoggedIn(false);
                setIsLoading(false);
            }
        });
        return function () {
            unregisterAuthListener();
        };
    }, []);
    var logout = function () {
        return firebaseAuth
            .signOut()
            .then(function () {
            analytics.triggerEvent(GTMEventType.SignOut, {
                category: GTMEventGategory.User,
            });
        })
            .catch(function (error) {
            console.error('Error signing user out.', error); // eslint-disable-line no-console
        });
    };
    return { idToken: idToken, isLoggedIn: isLoggedIn, isLoading: isLoading, logout: logout };
};
