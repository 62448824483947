export var breakpoints = {
    xs: {
        mediaQuery: null,
        containerWidthPixels: 'auto',
        maxWidthPixels: 575,
    },
    sm: {
        mediaQuery: 'min-width: 576px',
        containerWidthPixels: 540,
    },
    md: {
        mediaQuery: 'min-width: 768px',
        containerWidthPixels: 720,
    },
    lg: {
        mediaQuery: 'min-width: 992px',
        containerWidthPixels: 960,
    },
    xl: {
        mediaQuery: 'min-width: 1200px',
        containerWidthPixels: 1140,
    },
};
