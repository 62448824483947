var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
import React, { useState } from 'react';
import styled from 'styled-components';
import { FaChevronCircleRight, FaChevronCircleDown } from 'react-icons/fa';
import { mapSubcategoryToColor } from 'app/theme';
export var Subcategory = function (_a) {
    var id = _a.id, name = _a.name, expanded = _a.expanded, children = _a.children, _b = _a.vertical, vertical = _b === void 0 ? false : _b;
    var categoryColor = mapSubcategoryToColor(id);
    var _c = useState(expanded), categoryExpanded = _c[0], setCategoryExpanded = _c[1];
    var toggleExpanded = function () {
        setCategoryExpanded(!categoryExpanded);
    };
    var getIcon = function () {
        var iconStyle = { fontSize: '1.6rem' };
        return categoryExpanded ? (React.createElement(FaChevronCircleDown, { color: categoryColor, style: iconStyle })) : (React.createElement(FaChevronCircleRight, { color: categoryColor, style: iconStyle }));
    };
    return (React.createElement(Wrapper, null,
        React.createElement(Title, { color: categoryColor, expanded: categoryExpanded, onClick: toggleExpanded },
            React.createElement(Name, null, name),
            getIcon()),
        React.createElement(ChildWrapper, { expanded: categoryExpanded, vertical: vertical }, children)));
};
var Wrapper = styled.div(templateObject_1 || (templateObject_1 = __makeTemplateObject([""], [""])));
var ChildWrapper = styled.div(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n  display: flex;\n  flex-direction: ", ";\n  flex-wrap: wrap;\n  height: ", ";\n  opacity: ", ";\n  transition: all 0.5s ease-out;\n  overflow: hidden;\n"], ["\n  display: flex;\n  flex-direction: ", ";\n  flex-wrap: wrap;\n  height: ", ";\n  opacity: ", ";\n  transition: all 0.5s ease-out;\n  overflow: hidden;\n"])), function (_a) {
    var vertical = _a.vertical;
    return (vertical ? 'column' : 'row');
}, function (_a) {
    var expanded = _a.expanded;
    return (expanded ? 'auto' : '0');
}, function (_a) {
    var expanded = _a.expanded;
    return (expanded ? '1' : '0');
});
var Title = styled.h3(templateObject_3 || (templateObject_3 = __makeTemplateObject(["\n  display: inline-flex;\n  align-items: center;\n  color: ", ";\n  font-size: 1.6rem;\n  font-weight: 600;\n  font-family: ", ";\n  letter-spacing: 0.11rem;\n  margin: ", ";\n  transition: all 0.5s ease-out;\n  cursor: pointer;\n"], ["\n  display: inline-flex;\n  align-items: center;\n  color: ", ";\n  font-size: 1.6rem;\n  font-weight: 600;\n  font-family: ", ";\n  letter-spacing: 0.11rem;\n  margin: ", ";\n  transition: all 0.5s ease-out;\n  cursor: pointer;\n"])), function (_a) {
    var color = _a.color;
    return color;
}, function (_a) {
    var theme = _a.theme;
    return theme.font.secondary;
}, function (_a) {
    var expanded = _a.expanded;
    return (expanded ? '2.5rem 0 2.5rem' : '2.5rem 0 0');
});
var Name = styled.span(templateObject_4 || (templateObject_4 = __makeTemplateObject(["\n  margin-right: 7px;\n"], ["\n  margin-right: 7px;\n"])));
var templateObject_1, templateObject_2, templateObject_3, templateObject_4;
